import {Inject, Injectable, Injector} from '@angular/core';
import {HalService} from './hal.service';
import {map} from 'rxjs/operators';
import * as R from 'ramda';
import {ExternalConfigurationHandlerInterface} from 'angular4-hal';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Task} from '../models/entities/task.model';
import {Order} from '../models/entities/order.model';
import {Entity} from '../models/entity.model';
import {Observable} from 'rxjs';
import {Result} from '../models/result.model';
import {Department} from '../models/entities/department.model';
import {Instruction} from '../models/entities/instruction.model';
import {Handbook} from '../models/entities/handbook.model';
import {User} from '../models/entities/user.model';
import {WorkStep} from '../models/entities/workstep.model';
import {isNil} from '../helpers/common';

@Injectable({
    providedIn: 'root'
})
export class TaskService extends HalService<Task> {

    public task: Task;
    private taskTrackingId = '';

    constructor(injector: Injector, httpC: HttpClient, @Inject('ExternalConfigurationService') private exConf: ExternalConfigurationHandlerInterface) {
        super(Task, injector, httpC, exConf);
    }

    getChildren(ownerId: string): Observable<Entity[]> {
        return this.customQuery('/task/search/cgetChildren', {params: [{key: 'projection', value: 'detail'}, {key: 'owner', value: ownerId}]});
    }

    getTask(uuid: string): Observable<Task> {
        return this.customQueryGet('/task/' + uuid, [{key: 'projection', value: 'backend'}]);
    }

    getRequiredLockedTask(uuid: string): Observable<Task> {
        return this.customQueryGet('/task/' + uuid + '/lock', [{key: 'projection', value: 'details'}]);
    }

    unlocktask(uuid: string): Observable<Task> {
        return this.customQueryGet('/task/' + uuid + '/unlock', [{key: 'projection', value: 'details'}]);
    }

    getOrder(uuid: string): Observable<Order> {
        return this.customQueryGet('/order/' + uuid, [{key: 'projection', value: 'backend'}]);
    }

    putTask(uuid: string, task: Task): Observable<Task> {
        return this.customPut('/task/' + uuid + '/dispo', task);
    }

    changeDateTask(uuid: string, task: Task): Observable<Task> {
        return this.customPut('/task/' + uuid + '/changeDate', task);
    }

    freeTask(uuid: string, task: Task): Observable<Task> {
        return this.customPut('/task/' + uuid + '/freetask', task);
    }

    completeTaskStep(uuid: string, task: Task, num: number = 0): Observable<Task> {
        return this.customPut('/task/' + uuid + '/completeStep/' + num, task);
    }

    pauseTask(uuid: string, task: Task): Observable<Task> {
        return this.customPut('/task/' + uuid + '/pausetask', task);
    }

    reserveTask(uuid: string, task: Task): Observable<Task> {
        return this.customPut('/task/' + uuid + '/reserve', task);
    }

    reopenTask(uuid: string, task: Task): Observable<Task> {
        return this.customPut('/task/' + uuid + '/reopen', task);
    }

    clearWorksteps(uuid: string): Observable<Task> {
        return this.customPut('/task/' + uuid + '/clearsteps', {});
    }

    getDepartments(): Observable<Department[]> {
        return this.customQueryGet('/department/all', [{key: 'projection', value: 'detaildispo'}]).pipe(map((source: Result) => source._embedded ? R.pluck('content', source._embedded.persistentEntityResources) : []));
    }

    getUsers(): Observable<User[]> {
        return this.customQueryGet('/user/dispo', [{key: 'projection', value: 'detaildispo'}]).pipe(map((source: Result) => source._embedded ? R.pluck('content', source._embedded.persistentEntityResources) : []));
    }

    getDepartmentConfig(uuid: string): Observable<any> {
        return new Observable<any>(subscriber => {
            this.getDepartments().subscribe(() => {
                const hasDepartments = dc => R.length(dc.departments) > 0;
                this.customQueryGet('/departmentconfig/task/' + uuid).pipe(map(value => R.filter(hasDepartments, value))).subscribe(departmentConfigs => {
                    subscriber.next(departmentConfigs);
                    subscriber.complete();
                });
            });
        });
    }

    getStringForDepartmentConfigs(configs, departments) {
        return R.join(', ', R.map(config => R.find(R.propEq('id', config), departments).name, configs));
    }

    getWorkplaces(): Observable<string[]> {
        return this.customQueryGet('workplace/list').pipe(map((source: string[]) => {
            // console.log('workplace', source);
            return source;
        }));
    }

    getWorkStepCount(): Observable<number> {
        return this.getAllWorkSteps().pipe(map(source => source.length));
    }

    getErrorWorkSteps(): Observable<any> {
        return this.customQuery('workstep/all', {size: 100}).pipe(map(source => {
            const worksteps = R.filter(
                (step: WorkStep) => step.workstepType === 'ERRORSTEP' || isNil(step.workstepType),
                R.sortBy(R.prop('selectionorder'), R.pluck('content', source))
            );
            return worksteps;
        }));
    }

    getAllWorkSteps(): Observable<any> {
        return this.customQuery('workstep/all', {size: 100}).pipe(map(source => {
            const worksteps = R.filter(
                (step: WorkStep) => step.workstepType === 'NORMALSTEP' || isNil(step.workstepType),
                R.sortBy(R.prop('selectionorder'), R.pluck('content', source))
            );
            return worksteps;
        }));
    }

    getUserWorkSteps(): Observable<any> {
        return this.customQuery('workstep/user', {size: 100}).pipe(map(source => {
            const worksteps = R.filter(
                (step: WorkStep) => step.workstepType === 'NORMALSTEP' || isNil(step.workstepType),
                R.sortBy(R.prop('selectionorder'), R.pluck('content', source))
            );
            return worksteps;
        }));
    }

    getInstructions(task: Task): Observable<Instruction[]> {
        const queryString = 'client' + encodeURIComponent('%' + task.client) + ',erpnumber' + encodeURIComponent('%' + task.erpnumber);
        return this.customQueryGet('/instruction/search/dslquery?search=' + queryString, [])
            .pipe(map((result: Result) => result._embedded ? result._embedded.instruction : []))
            .pipe(map((instructions: Instruction[]) => R.map((instruction: Instruction) => new Instruction().deserialize(instruction), instructions)));
    }


    getHandbooks(task: Task): Observable<Handbook[]> {
        const queryString = 'client' + encodeURIComponent('%' + task.client) + ',erpnumber' + encodeURIComponent('%' + task.erpnumber);
        return this.customQueryGet('/handbook/search/dslquery?search=' + queryString, [])
            .pipe(map((result: Result) => result._embedded ? result._embedded.handbook : []))
            .pipe(map((handbooks: Handbook[]) => {
                return R.map((handbook: Handbook) => {
                    const handbookEntity = new Handbook().deserialize(handbook);
                    this.getPdfContent(handbookEntity).subscribe((content: string) => handbookEntity.pdfContent = 'data:application/pdf;base64,' + content);
                    return handbookEntity;
                }, handbooks);
            }));
    }

    addHandbook(task: Task, handbook: Handbook, file): Observable<Handbook> {
        return new Observable<Handbook>(subscriber => {
            subscriber.syncErrorThrowable = true;
            handbook.client = task.client;
            handbook.erpnumber = task.erpnumber;
            this.customPost('/handbook', handbook.getClean()).subscribe(
                (addedHandbook: Handbook) => {
                    const newHandbook = new Handbook().deserialize(addedHandbook);
                    if (file !== undefined && !R.isEmpty(file)) {
                        const uploadData = new FormData();
                        uploadData.append('file', file, file.name);

                        this.customPost('/upload/handbook/' + newHandbook.identifier, uploadData)
                            .subscribe(() => {
                                subscriber.next(new Handbook());
                                subscriber.complete();
                            });
                    } else {
                        subscriber.next(new Handbook());
                        subscriber.complete();
                    }
                    return newHandbook;
                });
        });
    }

    deleteHandbook(id:string){
        // TODO: Implement remove Handbook
        return this.customDelete('/handbook/' + id);
    }
teamsprint_teamsprint_1
    addInstructions(task: Task, instruction: Instruction, file): Observable<Instruction> {
        return new Observable<Instruction>(subscriber => {
            subscriber.syncErrorThrowable = true;
            instruction.client = task.client;
            instruction.erpnumber = task.erpnumber;
            this.customPost('/instruction', instruction.getClean()).subscribe(
                (addedInstruction: Instruction) => {
                    const newInstruction = new Instruction().deserialize(addedInstruction);
                    if (file !== undefined && !R.isEmpty(file)) {
                        const uploadData = new FormData();
                        uploadData.append('file', file, file.name);

                        this.customPost('/upload/instruction/' + newInstruction.identifier, uploadData).subscribe(
                            () => {
                                subscriber.next(new Instruction());
                                subscriber.complete();
                            });
                    } else {
                        subscriber.next(new Instruction());
                        subscriber.complete();
                    }
                    return newInstruction;
                });
        });
    }

    updateInstructions(instruction: Instruction): Observable<any> {
        return this.customPost(`/instruction/${instruction.identifier}`, instruction.getClean());
    }

    getPdfContent(handbook: Handbook): Observable<any> {
        return this.httpClient.get(handbook.getResource());
    }

    getCalender(start: Moment, end: Moment) {
        const params = [{key: 'fromdate', value: start.format(this.dateFormatFull)}, {key: 'todate', value: end.format(this.dateFormatFull)}, {key: 'step', value: 15}, {key: 'projection', value: 'details'}];
        return this.customQuery(`task/search/calender`, {size: 1000, params});
    }

    startTracking(task: Task): void {
        if (this.taskTrackingId === '') {
            this.privateStartTracking(task);
        } else {
            this.customQuery('/tasktracking/' + this.taskTrackingId + '/close').subscribe(() => {
                this.taskTrackingId = '';
                this.privateStartTracking(task);
            });
        }
    }

    stopTracking(): void {
        if (this.taskTrackingId !== '') {
            this.customPut('/tasktracking/' + this.taskTrackingId + '/close', {}).subscribe(() => this.taskTrackingId = '');
        }
    }

    createTaskState(task: Task, labels, worksteps, availableDepartments, availableUsers) {
        const checkLabels = lables => R.includes(lables, labels);
        const checkLabelsInvert = lables => !R.includes(lables, labels);
        const filterSelectionOrder = workstep => workstep.selectionorder === task.step;
        const filterDepartments = department => R.includes(department.id, task.departmentIds);
        const filterWorker = worker => worker.id === task.userId;

        const state = {
            date: moment(task.date).toDate(),
            quantity: task.quantity,
            customlabel: R.join(',')(R.filter(checkLabelsInvert, task.labels)),
            labels: R.filter(checkLabels, task.labels),
            deliveryDate: moment(task.deliveryDate).toDate(),
            timea: task.timea,
            timeb: task.timeb,
            timec: task.timec,
            workplace: task.place,
            workstep: R.head(R.filter(filterSelectionOrder, worksteps)),
            departments: R.filter(filterDepartments, availableDepartments),
            worker: R.head(R.filter(filterWorker, availableUsers))
        };
        // console.log('Set task state:', state, task);
        return state;
    }

    callProductionManager(message: string, task: Task): Observable<any> {
        return this.customPost('/email/informFertigungsleiter', {
            message,
            taskid: task.id
        });
    }

    callQaManager(message: string, task: Task): Observable<any> {
        return this.customPost('/email/informQuali', {
            message,
            taskid: task.id
        });
    }

    findChildWorksteps(workstep: string) {
        return this.customQueryGet('/workstep/search/findAllByParent', [{key: 'parent', value: workstep}])
            .pipe(map(source => {
                if (!isNil(source._embedded)) {
                    return source._embedded.workstep;
                }
            }));
    }

    private privateStartTracking(task: Task) {
        const data = {
            client: task.client,
            erpnumber: task.erpnumber,
            task_id: task.id,
            order_id: task.order_id,
            user_id: '',
            quantity: task.quantity,
            place: task.place,
            step: task.step,
            worker: '',
            priority: task.priority,
            status: task.status,
        };
        this.customPost('/tasktracking', data).subscribe((id: string) => this.taskTrackingId = id);
    }
}
