import {Resource} from 'angular4-hal';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Link} from './result.model';
import * as R from 'ramda';

export class Entity extends Resource {

    public date: string;
    public _id: string;
    public _links: { [name: string]: Link };

    public name?: string;
    public fullname?: string;
    public client?: string;

    get identifier(): string {
        const i = this._links.self.href.lastIndexOf('/');
        return this._links.self.href.substr(i + 1);
    }

    get routes(): { edit: string, uuid: string } {
        const a = this._links.self.href.split('/');
        return {edit: a[a.length - 2] + '/' + this.identifier, uuid: this.identifier};
    }

    get moment(): Moment {
        return moment(this.date || '');
    }

    getResource(): string {
        return this._links.resourcesEntity ? R.replace(/{\?.+}/gm, '', this._links.resourcesEntity.href) : null;
    }

}

export class Relation extends Entity {
}

export class AutoCompleteOptions {
    constructor(public path: string, public key: string, public value: string, public size: number, public display?: string) {
    }
}
