import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfigService} from '../../../services/config.service';
import {Location} from '@angular/common';
import {DetailComponent} from '../detail.component';
import * as moment from 'moment';
import {Instruction, ResourceEntity} from '../../../models/entities/instruction.model';
import {User} from '../../../models/entities/user.model';
import {UserService} from '../../../services/user.service';
import {TaskService} from '../../../services/task.service';
import {ErrorTracking} from '../../../models/entities/error-tracking.model';
import {Error} from '../../../models/entities/error.model';
import {ErrorTrackingService} from '../../../services/error-tracking.service';
import {Handbook} from '../../../models/entities/handbook.model';
import {ErrorReportDetails} from '../../../models/entities/error-report-details.model';
import {Order} from '../../../models/entities/order.model';
import {Task} from 'src/app/models/entities/task.model';
import {ErrorReport} from '../../../models/entities/error-report.model';
import {Department} from '../../../models/entities/department.model';
import {NotificationService} from '../../../services/notification.service';
import {combineLatest} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {WorkStep} from '../../../models/entities/workstep.model';
import {ImageFile} from '../../edit/edit.component';
import {MatDialog} from '@angular/material';
import {ErrorReportService} from '../../../services/error-report.service';
import {isNil} from '../../../helpers/common';
import {AppComponent} from '../../../app.component';

@Component({
    selector: 'app-quality',
    templateUrl: './quality.component.html',
    styleUrls: ['./quality.component.scss', '../detail.component.scss']
})
export class QualityComponent extends DetailComponent implements OnInit {

    @ViewChild('freeTaskDialog', {static: true}) freeTaskDialog: TemplateRef<any>;

    public details: ErrorReportDetails;
    public orderEntity: Order;
    public userEntity: User;
    public errorreportEntity: ErrorReport;
    public tasktrackingEntityList: Error[];
    public instructionEntityList: Instruction[];
    public state = {details: {}, taskEntity: {}, orderEntity: {}, userEntity: {}, errorreportEntity: {}, tasktrackingEntityList: [], instructionEntityList: [], availableDepartments: [], workstepCount: 0};
    public report: { key: string, value: any }[] = [];
    public detail: { key: string, value: any }[] = [];
    public source: any[] = [];
    public images: { link: any, name: string, content: string, created: string }[] = [];
    public errorWorksteps: WorkStep[];
    private errorTrackingId: string;
    private errorTracking: ErrorTracking;
    private image: ImageFile;

    constructor(r: ActivatedRoute, ts: TaskService, sc: ConfigService, l: Location, n: NotificationService, private us: UserService, private dialog: MatDialog, private errorTrackingService: ErrorTrackingService, private sanitizer: DomSanitizer, private router: Router, private errorReportService: ErrorReportService, private a: AppComponent) {
        super(r, ts, sc, l, n, us, a);
    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.errorTrackingService.getErrorTracking(params.uuid).subscribe(
                (errorTracking: ErrorTracking) => {
                    this.errorTrackingId = params.uuid;
                    this.errorTracking = errorTracking;
                    combineLatest([
                        this.taskService.getTask(errorTracking.task_id),
                        this.taskService.getOrder(errorTracking.order_id),
                        this.userService.getUser(errorTracking.user_id),
                        this.errorTrackingService.getErrorReport(errorTracking.errorreport_id),
                        this.taskService.getDepartments(),
                        this.errorTrackingService.getDetails(params.uuid),
                        this.taskService.getAllWorkSteps(),
                        this.taskService.getErrorWorkSteps(),
                    ]).subscribe((result: any[]) => {
                        this.task = result[0] as Task;
                        this.orderEntity = result[1] as Order;
                        this.userEntity = result[2] as User;
                        this.availableDepartments = result[4] as Department[];
                        this.workstepCount = (result[6] as WorkStep[]).length as number;
                        this.tasktrackingEntityList = result[5].tasktrackingEntityList as Error[];
                        this.instructionEntityList = result[5].instructionEntityList as Instruction[];
                        this.errorreportEntity = new ErrorReport().deserialize(result[5].errorreportEntity);
                        this.details = result[5] as ErrorReportDetails;
                        this.taskService.getInstructions(this.state.taskEntity as Task).subscribe((instructions: Instruction[]) => this.instructions = instructions);
                        this.taskService.getHandbooks(this.state.taskEntity as Task).subscribe((handbooks: Handbook[]) => this.handbooks = handbooks);
                        this.worksteps = result[6] as WorkStep[];
                        this.errorWorksteps = result[7] as WorkStep[];

                        // right column
                        this.report.push(
                            {key: 'originalQuantity', value: this.workstepCount.toString()},
                            {key: 'erpnumber', value: this.task.erpnumber},
                            {key: 'client', value: this.task.client},
                            {key: 'department', value: this.task.departmentEntityList.length ? this.task.departmentEntityList.join(', ') : null},
                            {key: 'date', value: moment(this.task.date).format(this.taskService.dateFormat.toUpperCase())}
                        );

                        // left column
                        this.detail.push(
                            {key: 'error_resolved', value: this.errorTracking.resolved ? 'resolved' : 'not resolved'},
                            // {key: 'error_message', value: this.errorreportEntity.content},
                            {key: 'worker', value: this.task.worker},
                            {key: 'errorStep', value: this.errorreportEntity.errorStep},
                            {key: 'status', value: this.task.status},
                        );

                        // middle column
                        this.tasktrackingEntityList.map((error: Error) => {
                            const data = [
                                {key: 'worker', value: error.worker},
                                {key: 'start', value: moment(error.open).format(this.taskService.dateFormat.toUpperCase())},
                                {key: 'end', value: moment(error.close).format(this.taskService.dateFormat.toUpperCase())},
                                {key: 'processing time', value: this.getDiff(error.close, error.open)},
                                {key: 'processed at', value: this.fromNow(error.close)},
                                {key: 'step', value: this.worksteps.length && error.step ? this.worksteps.find((workstep: WorkStep) => workstep.selectionorder === error.step).name : null},
                                {key: 'status', value: error.status},
                                {key: 'place', value: error.place},
                            ];
                            this.source.push(data);
                        });

                        this.instructionEntityList.map((instruction: Instruction) => this.images.push({
                            link: this.getFileUrl(instruction.resourcesEntity),
                            name: instruction.resourcesEntity.filename,
                            content: instruction.content,
                            created: instruction.resourcesEntity.created
                        }));

                        const resource = this.errorreportEntity.resourcesEntity as ResourceEntity || null;
                        if (resource) {
                            this.images.push({link: this.getFileUrl(resource), name: resource.filename, content: this.errorreportEntity.content, created: resource.created});
                        }
                    });
                });
        });
    }

    public onOpenDialog() {
        this.dialog.open(this.freeTaskDialog);
    }

    public onFreeTask(value: { message: string, showAsWarning: boolean, resetWorksteps: boolean }) {
        // console.log({image: this.image, message: value.message}, value);
        if (!this.errorTracking.resolved) {
            this.errorreportEntity.resolvedMessage = value.message;
            this.errorreportEntity.showAsWarning = value.showAsWarning;
            const resetWorksteps = value.resetWorksteps;

            this.errorReportService.updateErrorreport(this.errorreportEntity).subscribe(value1 => {
                // Upload Picture;
                if (!isNil(this.image)) {
                    // console.log('Has image. Try uploadErrorrportResolvedPicture');
                    this.errorReportService.uploadErrorrportResolvedPicture(this.errorreportEntity.id, this.image).subscribe(value2 => {
                        // console.log('Upload ResolvePicture Done!');
                        this.taskService.reopenTask(this.errorTrackingId, this.task).subscribe(() => {
                            if (resetWorksteps) {
                                this.taskService.clearWorksteps(this.task.id).subscribe();
                            }
                            this.router.navigate(['/grid', 'quality'], {relativeTo: this.route});
                        });
                    });
                } else {
                    this.taskService.reopenTask(this.errorTrackingId, this.task).subscribe(() => {
                        if (resetWorksteps) {
                            this.taskService.clearWorksteps(this.task.id).subscribe();
                        }
                        this.router.navigate(['/grid', 'quality'], {relativeTo: this.route})
                    });
                }

            });
        }

        // TODO
        // if (!this.errorTracking.resolved) {
        //     this.taskService.reopenTask(this.errorTrackingId, this.task).subscribe(() => this.router.navigate(['/grid', 'qualtiy'], {relativeTo: this.route}));
        // }
    }

    // navigate back
    public onNavigateBack() {
        this.router.navigate(['/grid', 'quality'], {relativeTo: this.route});
    }

    private onFileChanged(file: ImageFile) {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => (file as ImageFile).url = reader.result;
            this.image = file;
        }
    }

    private getFileUrl = (resource: ResourceEntity) => this.sanitizer.bypassSecurityTrustResourceUrl(this.taskService.rootUri + 'download/stream/' + resource.id);

    private getDiff = (close, open) => moment.duration(moment(close).diff(moment(open), 'minutes'), 'minutes').humanize(false);

    private fromNow = (close) => moment.duration(moment(close).diff(moment(), 'minutes'), 'minutes').humanize(true);
}
