import {Deserializable} from '../deserializable.model';
import {Entity} from '../entity.model';
import * as R from 'ramda';

export class Handbook extends Entity implements Deserializable {
    public client: string;
    public content: string;
    public erpnumber: string;
    public major: boolean;
    public selectionorder: number;
    public pdfContent: string;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    getResource() {
        return R.replace(/{\?.+}/gm, '', this._links.resourcesEntityPdf.href);
    }

    getClean() {
        return {
            client: this.client,
            content: this.content,
            erpnumber: this.erpnumber,
            major: this.major,
            selectionorder: this.selectionorder
        };
    }
}
