import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {NotificationService} from '../services/notification.service';
import {UserService} from '../services/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private notificationService: NotificationService, private userService: UserService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // remove double slashes
        const req = request.clone({withCredentials: true, responseType: 'json', url: request.url.replace(/([^:]\/)\/+/g, '$1')});
        const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
        this.notificationService.isBuffering.next(true);
        return next.handle(req).pipe(
            finalize(() => this.notificationService.isBuffering.next(false)),
            catchError((error: HttpErrorResponse) => {
                // if 0 Unknown Error returned from api -> user is not logged in anymore
                if (error.status === 0) {
                    this.userService.clear();
                    this.router.navigate(['/login'], {queryParams: snapshot.url.includes('returnUrl') ? snapshot.root.queryParams : {returnUrl: snapshot.url}});
                }
                return throwError(error);
            })
        );
    }
}
