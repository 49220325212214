import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { environment } from '../../../environments/environment';
import { User } from '../../models/entities/user.model';
import { UserService } from '../../services/user.service';
import { Result } from '../../models/result.model';
import { EntityService } from '../../services/entity.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    public displayLegends = true;
    public chartOptions: { plugins: { datalabels: { formatter: (value, ctx) => string | string[] | number | number[] | Date | Date[] | moment.Moment | moment.Moment[] } }; responsive: boolean; maintainAspectRatio: boolean; tooltips: { callbacks: { label(tooltipItem, data): string; title(tooltipItem, data): string | string[] | number | number[] | Date | Date[] | moment.Moment | moment.Moment[] }; displayColors: boolean } } = {
        responsive: true,
        maintainAspectRatio: false,
        // legend: {position: 'left'},
        plugins: { datalabels: { formatter: (value, ctx) => ctx.chart.data.labels[ctx.dataIndex] } },
        tooltips: {
            callbacks: {
                title(tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                },
                label(tooltipItem, data) {
                    const dataset = data.datasets[0];
                    const value = dataset.data[tooltipItem.index] as number;
                    // @ts-ignore
                    const total = dataset._meta[Object.keys(dataset._meta).map(key => key)].total as number;
                    const percent = Math.round((value / total) * 100);
                    // TODO: use translation service
                    return `${total} Stück (${percent}%)`;
                }
            },
            displayColors: false
        }
    };
    public logarithmicChartOptions = {
        // legend: {position: 'bottom'},
        scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{}], yAxes: [{
                type: 'logarithmic', ticks: {
                    min: 0.1, // minimum tick
                    // max: 5000, // maximum tick
                    callback: (value) => Number(value.toString()) // pass tick values as a string into Number function
                },
                afterBuildTicks: (chartObj) => { // Build ticks labelling as per your need
                    chartObj.ticks = [];
                    chartObj.ticks.push(0.1);
                    chartObj.ticks.push(1);
                    chartObj.ticks.push(10);
                    chartObj.ticks.push(100);
                    chartObj.ticks.push(1000);
                    chartObj.ticks.push(10000);
                }
            }]
        }
    };
    public logarithmicChartType = 'bar';
    public chartLabel: Label[] = [];
    public chartData: any[] = [];
    public chartColor = [];
    public logarithmicChartData: ChartDataSets[] = [];
    public counter: { name: string, number: number }[] = [];
    private currentMonth = +moment().format('M') as number;
    public logarithmicChartLabel: Label[] = (Array.apply(0, Array(this.currentMonth)).map((_, i) => moment().month(i)) as any[]).map((date: Moment) => moment(date).format('MMM'));
    public user: User;
    private status = ['done', 'failed', 'in-progress', 'reserved', 'disponent', 'null', ''];

    constructor(private entityService: EntityService, public userService: UserService, private translateService: TranslateService, private router: Router, private breakpointObserver: BreakpointObserver) {
        breakpointObserver.observe('(min-width: 600px)').subscribe((result: BreakpointState) => this.displayLegends = result.matches);
        this.user = this.userService.user;
    }

    ngOnInit() {

        this.entityService.getRootLinks().subscribe(
            (links: string[]) =>
                links.filter((key: string) => environment.filter.sidenav.includes.includes(key)).map((name: string) => this.entityService.get(name).subscribe((result: Result) => this.counter.push({ name, number: result.page.totalElements })))
        );

        // get status distribution of the current user
        this.entityService.customQueryGet('task/search/ccountStatusAll', [{ key: 'uuid', value: this.user.id }]).subscribe(
            (result: { '': number; done: number; failed: number; 'in-progress': number; null: number; reserved: number }) => {
                this.chartLabel[0] = Object.keys(result).map((status: string) => this.translateService.instant(status === '' ? 'undefined' : status)) as Label;
                this.chartColor[0] = [{ backgroundColor: Object.keys(result).map((status: string) => this.translateService.instant('color.' + (status === '' ? 'undefined' : status))) }];
                this.chartData[0] = Object.values(result);
            });


        // get status distribution of all users
        this.chartLabel[1] = [];
        this.chartColor[1] = [{ backgroundColor: [] }];
        this.chartData[1] = [];

        this.status.map((status: string) =>
            this.entityService.customQueryGet('task/search/dslquery', [{ key: 'search', value: `status:${status}` }, { key: 'size', value: 1 }]).subscribe(
                (result: Result) => {
                    (this.chartLabel[1] as Label[]).push(this.translateService.instant(status === '' ? 'undefined' : status));
                    this.chartColor[1][0].backgroundColor.push(this.translateService.instant('color.' + (status === '' ? 'undefined' : status)));
                    this.chartData[1].push(result.page.totalElements);
                })
        );

        // get status distribution per month
        setTimeout(() => {
            this.status.map((status: string) => {
                const data = [];
                const label = this.translateService.instant(status === '' ? 'undefined' : status);
                const backgroundColor = this.translateService.instant('color.' + (status === '' ? 'undefined' : status));
                this.logarithmicChartLabel.map(
                    (date: string) => {
                        // set start and end date
                        const start = moment().month(date).startOf('month').toISOString(true);
                        const end = moment().month(date).endOf('month').toISOString(true);

                        this.entityService.customQueryGet('task/search/dslquery', [{ key: 'search', value: `date>${start},date<${end},status:${status}` }, { key: 'size', value: 1 }]).subscribe((result: Result) => data.push(result.page.totalElements));
                    }
                );
                this.logarithmicChartData.push({ data, label, backgroundColor, borderColor: backgroundColor, hoverBackgroundColor: backgroundColor, hoverBorderColor: backgroundColor });
            });
        }, 1000);
    }

    public onLogout() {
        this.userService.logout().subscribe(() => this.router.navigate(['/login']));
    }

    private onSetLanguage(lang: string) {
        localStorage.setItem('lang', lang);
        this.translateService.use(lang);
    }
}
