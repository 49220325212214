import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DisponentComponent} from '../disponent/disponent.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TaskService} from '../../../services/task.service';
import {ConfigService} from '../../../services/config.service';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material';
import {Notification, NotificationService} from '../../../services/notification.service';
import {UserService} from '../../../services/user.service';
import {Task} from '../../../models/entities/task.model';
import * as R from 'ramda';
import {WorkStep} from '../../../models/entities/workstep.model';
import {isNil} from '../../../helpers/common';
import {AppComponent} from '../../../app.component';

@Component({
    selector: 'app-worker',
    templateUrl: './worker.component.html',
    styleUrls: ['./worker.component.scss', '../detail.component.scss']
})
export class WorkerComponent extends DisponentComponent implements OnInit {
    @ViewChild('imageDialog', {static: true}) imageDialog: TemplateRef<any>;
    public userWorksteps: WorkStep[] = [];

    constructor(r: ActivatedRoute, ts: TaskService, sc: ConfigService, l: Location, n: NotificationService, private dialog: MatDialog, private ro: Router, private users: UserService, public a: AppComponent) {
        super(r, ts, sc, l, n, ro, users, a);
    }

    public onOpenImageDialog(index: number) {
        this.dialog.open(this.imageDialog, {data: index});
    }

    public onReserveTask() {
        this.reserve('reserved').subscribe(
            () => this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.info.reserved', {value: 'Auftrag'}))),
            () => this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.error.reserved', {value: 'Auftrag'}))),
            () => this.router.navigate(['/grid', 'shop'], {relativeTo: this.route}),
        );
    }

    public onExecuteTask() {
        this.reserve('in-progress').subscribe(
            (value) => {
                this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.info.execute', {value: 'Auftrag'})));
                this.router.navigate(['/detail', 'in-progress', value.id], {relativeTo: this.route});
            },
            () => this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.error.execute', {value: 'Auftrag'})))
        );
    }

    ngOnInit() {
        this.init().subscribe((task: Task) => {
            console.log('Show the task:', task);
            if (this.userService.isFreeUser()) {
                if (task.departmentSteps.length > 0) {
                    this.userWorksteps = R.filter(step => R.includes(step.selectionorder, task.departmentSteps) || step.workstepType === 'ERRORSTEP', this.worksteps);
                } else {
                    this.userWorksteps = this.worksteps;
                }
            } else {
                this.userWorksteps = R.filter(step => (
                    R.includes(step.selectionorder, this.userService.getValidSteps())
                    && R.includes(step.selectionorder, task.departmentSteps)
                ) || step.workstepType === 'ERRORSTEP', this.worksteps);
            }
            this.setState(this.taskService.createTaskState(task, this.labels, this.worksteps, this.availableDepartments, this.availableUsers));
            if (!isNil(this.userService.getWorkplace())) {
                this.setState({workplace: this.userService.getWorkplace()});
            }
        });
    }

    public onNavigateBack() {
        this.taskService.unlocktask(this.task.id).subscribe(value => {
            this.router.navigate(['/grid', 'shop'], {relativeTo: this.route});
        });
    }

    private reserve(status: string = 'in-progress' || 'reserved') {
        const {quantity, workstep, workplace} = this.state;
        Object.assign(this.task, {status, step: workstep ? workstep.selectionorder : this.task.step, place: workplace ? workplace : this.task.place, quantity: quantity ? quantity : this.task.quantity});
        return this.taskService.reserveTask(this.task.id, this.task);
    }
}
