import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {environment} from 'src/environments/environment';
import {User} from '../models/entities/user.model';

@Injectable()

export class AuthGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser: User = this.userService.user;

        if (currentUser) {
            const path = route.params.path || route.params.mode || state.url.substr(1, route.params.uuid ? state.url.indexOf(route.params.uuid) - 2 : state.url.length);
            // check if route is restricted by role
            if (route.data.accessControl && ((environment.access[path] && environment.access[path].indexOf(currentUser.grantedauthority) === -1) || environment.filter.sidenav.includes.indexOf(path) === -1)) {
                // role not authorised so redirect to home page
                this.router.navigate(['/error']);
                return false;
            }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
