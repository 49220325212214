import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  pure: false
})
export class HighlightPipe implements PipeTransform {
  transform(value: any, args: string[]): any {
    if (!value || !args || !args.length) {
      return value;
    }

    args.map((arg: string) => {
      const re = new RegExp(arg, 'gi');

      try {
        value = value.toString().replace(re, '<mark class="highlight">$&</mark>');
      } catch (error) {
        console.log(value);
      }
    });

    return value;
  }
}
