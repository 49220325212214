import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import 'moment/locale/de';
import {Subject} from 'rxjs';
import {MatDatepicker} from '@angular/material';
import {TaskService} from 'src/app/services/task.service';
import {Task} from 'src/app/models/entities/task.model';
import {NotificationService} from '../../services/notification.service';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

    // dates
    private dateFormat = 'dd.MM.yyyy';
    private today = moment().format();
    private date = moment().format();
    private selectedDate: Moment = moment();
    private dateChanged = new Subject<Moment>();
    private dateRage: Moment[] = [];
    private weekDays: string[] = moment.weekdays(true);
    private tasks: { date: string, tasks: Task[] }[] = [];

    constructor(private taskService: TaskService, private notificationService: NotificationService) {
    }

    ngOnInit() {
        this.initCalendar(this.selectedDate.month(), this.selectedDate.year());
        this.dateChanged.subscribe((date: Moment) => this.initCalendar(date.month(), date.year()));
    }

    // parse status -> set empty to null
    public parseStatus = (status: string) => status === '' ? null : status;

    private initCalendar(month: number, year: number) {
        // show loading indication
        this.notificationService.isLoading.next(true);
        // set start and end date
        const start = moment().year(year).month(month).startOf('month').startOf('week');
        const end = moment().year(year).month(month).endOf('month').endOf('week');
        this.dateRage = this.getRangeOfDates(start, end, 'day');

        this.tasks = []; // reset data
        // create empty month array
        this.dateRage.map((date: Moment) => this.tasks.push({date: date.format(this.dateFormat.toUpperCase()), tasks: []}));

        // creat http request
        this.taskService.getCalender(start, end).subscribe(
            (tasks: Task[]) => tasks.map((task: Task) => this.tasks.find((t: { date: string, tasks: Task[] }) => t.date === task.moment.format(this.dateFormat.toUpperCase())).tasks.push(task)),
            () => '',
            () => this.notificationService.isLoading.next(false)
        );
    }

    private isToday(date: Moment) {
        return date.format(this.dateFormat.toUpperCase()) === moment().format(this.dateFormat.toUpperCase());
    }

    private onChangeDate(type: string = 'next' || 'prev' || 'reset') {
        switch (type) {
            case 'next':
                this.selectedDate.add(1, 'month');
                break;
            case 'prev':
                this.selectedDate.subtract(1, 'month');
                break;
            case 'reset':
                this.selectedDate = moment();
                break;
        }
        this.date = this.selectedDate.format();
        this.dateChanged.next(this.selectedDate);
    }

    private onSelectDate(event: string, datepicker: MatDatepicker<any>) {
        this.selectedDate = moment(event);
        this.date = this.selectedDate.format();
        this.dateChanged.next(this.selectedDate);
        datepicker.close();
    }

    private getRangeOfDates(start, end, key, arr = [start.startOf(key)]) {
        if (start.isAfter(end)) {
            throw new Error('start must precede end');
        }
        const next = moment(start).add(1, key).startOf(key);
        if (next.isAfter(end, key)) {
            return arr;
        }
        return this.getRangeOfDates(next, end, key, arr.concat(next));
    }

}
