import {Inject, Injectable, Injector} from '@angular/core';
import {HalService} from './hal.service';
import {map} from 'rxjs/operators';
import {ExternalConfigurationHandlerInterface} from 'angular4-hal';
import {HttpClient} from '@angular/common/http';
import * as R from 'ramda';
import {Observable} from 'rxjs';
import {ErrorTracking} from '../models/entities/error-tracking.model';
import {Task} from '../models/entities/task.model';
import {ErrorReport} from '../models/entities/error-report.model';
import {Result} from '../models/result.model';
import {ImageFile} from '../components/edit/edit.component';
import {isNil} from '../helpers/common';

@Injectable({
    providedIn: 'root'
})
export class ErrorTrackingService extends HalService<ErrorTracking> {

    constructor(injector: Injector, httpC: HttpClient, @Inject('ExternalConfigurationService') private exConf: ExternalConfigurationHandlerInterface) {
        super(ErrorTracking, injector, httpC, exConf);
    }

    getErrorTracking(uuid: string): Observable<ErrorTracking> {
        return this.customQueryGet('/errortracking/' + uuid, []).pipe(map((source: ErrorTracking) => source));
    }

    getDetails(uuid: string): Observable<ErrorTracking> {
        return this.customQueryGet('/errortracking/' + uuid + '/details', []).pipe(map((source: ErrorTracking) => source));
    }

    getErrorReport(uuid: string): Observable<ErrorTracking> {
        return this.customQueryGet('/errorreport/' + uuid, []).pipe(map((source: ErrorTracking) => source));
    }

    getTaskTracking(uuid: string) {
        return this.customQueryGet('/tasktracking/' + uuid, []).pipe(map((source: ErrorTracking) => source));
    }

    getWarnings(task: Task): Observable<ErrorReport[]> {
        const queryString = 'erpnumber' + encodeURIComponent('%' + task.erpnumber) + ',client' + encodeURIComponent('%' + task.client) + ',deleted:false';
        return this.customQueryGet('/errorreport/search/dslquery?search=' + queryString, [])
            .pipe(map((source: Result) => source._embedded === undefined ? []
                : R.map((errorReport: ErrorReport) => new ErrorReport().deserialize(errorReport), source._embedded.errorreport)
                    .filter((errorReport: ErrorReport) => errorReport.showAsWarning)));
    }

    createErrorReport(task: Task, quantity: number, message: string, scrap: boolean = false, step: number, photos: ImageFile[]) {

        const data = {
            scrap,
            client: task.client,
            erpnumber: task.erpnumber,
            content: message,
            task_id: task.id,
            order_id: task.order_id,
            user_id: '',
            errorreport_id: '',
            quantity: task.quantity,
            place: task.place,
            step: task.step,
            worker: '',
            priority: task.priority,
            status: task.status,
            error_step: step,
            error_quantity: quantity,
            error_message: message
        };

        return this.customPost('/errorreport', data).pipe(map(source => {
            if (!isNil(photos) && !R.isEmpty(photos)) {
                this.uploadErrorPictures(source.id, photos).subscribe();
            }
            return source;
        }));
    }

    private uploadErrorPictures(id: string, photos: ImageFile[]) {
        const data = new FormData();

        for (let i = 1; i <= 3; i++) {
            if (!isNil(photos[i - 1])) {
                data.append('file_' + i, photos[i - 1], 'image-' + i + '.png');
            }
        }

        return this.customPost('/upload/errorreport/' + id, data);
    }

    private dataURLtoFile(dataurl, filename) {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }
}
