import {Deserializable} from '../deserializable.model';
import {Entity} from '../entity.model';
import {Order} from './order.model';
import { WorkStep } from './workstep.model';

export class Task extends Entity implements Deserializable {
    public client: string;
    public clientNumber: string;
    public date: string;
    public deleted: boolean;
    public deliveryDate: string;
    public departmentEntityList: string[];
    public departmentIds: object[];
    public departmentSteps: number[];
    public description: string;
    public enabled: boolean;
    public erpnumber: string;
    public erpnumberSmall: string;
    public id: string;
    public importPos: string;
    public labels: string[];
    public orderNumber: string;
    public order_id: string;
    public originalQuantity: number;
    public owner: boolean;
    public owningTask: object;
    public owningTaskId?: string;
    public owningTaskDescription: string;
    public owningTaskErp: string;
    public place: string;
    public priority: number;
    public qrCode: number[];
    public quantity: number;
    public status: string;
    public step: number | string;
    public timea: string;
    public timeb: string;
    public timec: string;
    public usedTime: string;
    public userId: string;
    public warnings: string;
    public worker: string;
    public workgroup: string;
    public lastWorkerEntity?: string;
    public order: Order;
    public completedWorksteps: WorkStep[];
    public taskLock: boolean;
    public lockedByUser: boolean;

    get owningTaskErpNumber() {
        const id = this._id;
        return id.substr(id.indexOf(this.owningTaskErp || this.erpnumber), id.length);
    }

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

}
