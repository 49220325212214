import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService} from '../../../services/config.service';
import {Location} from '@angular/common';
import * as moment from 'moment';
import * as R from 'ramda';
import {DetailComponent} from '../detail.component';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {ImageFile} from '../../edit/edit.component';
import {TaskService} from '../../../services/task.service';
import {Task} from 'src/app/models/entities/task.model';
import {Instruction} from '../../../models/entities/instruction.model';
import {Handbook} from '../../../models/entities/handbook.model';
import {isNil} from '../../../helpers/common';
import {User} from '../../../models/entities/user.model';
import {NotificationService} from '../../../services/notification.service';
import {UserService} from '../../../services/user.service';
import {WorkStep} from '../../../models/entities/workstep.model';
import {AppComponent} from '../../../app.component';

@Component({
    selector: 'app-disponent',
    templateUrl: './disponent.component.html',
    styleUrls: ['./disponent.component.scss', '../detail.component.scss'],
    providers: [{provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}}]
})
export class DisponentComponent extends DetailComponent implements OnInit {

    public state = {
        date: undefined,
        quantity: 0,
        workplace: '',
        workstep: undefined,
        departments: undefined,
        saveddepartments: undefined,
        worker: undefined,
        customlabel: undefined,
        labels: undefined,
        deliveryDate: undefined,
        timea: undefined,
        timeb: undefined,
        timec: undefined,
        workgroup: undefined,
    };

    private newInstructionContent = '';
    private newInstructionFile: ImageFile = undefined;

    private newHandbookContent = '';
    private newHandbookFile: File = undefined;

    constructor(r: ActivatedRoute, ts: TaskService, sc: ConfigService, l: Location, n: NotificationService, public router: Router, private us: UserService, public a: AppComponent) {
        super(r, ts, sc, l, n, us, a);
    }

    ngOnInit() {
        this.init().subscribe((task: Task) => this.setState(this.taskService.createTaskState(task, this.labels, this.worksteps, this.availableDepartments, this.availableUsers)));
    }

    updateTask() {
        const task = R.clone(this.task);
        const {quantity, date, workstep, timea, timeb, timec, deliveryDate, labels, customlabel, workplace, departments, saveddepartments, worker} = this.state;
        const aLabels = customlabel ? R.uniq(R.insert(1, customlabel, labels)) : labels;

        Object.assign(task, {
            date: date ? moment(date).toISOString(true) : task.date,
            step: workstep ? workstep.selectionorder : task.step,
            labels: aLabels || task.labels,
            deliveryDate: deliveryDate ? moment(deliveryDate).toISOString(true) : task.deliveryDate,
            timea: timea || task.timea,
            timeb: timeb || task.timeb,
            timec: timec || task.timec,
            place: workplace ? workplace : task.place,
            userId: worker ? worker.id : task.userId,
            status: worker ? 'reserved' : task.status,
            quantity: quantity ? quantity : task.quantity,
        });

        !R.isEmpty(saveddepartments) && saveddepartments !== undefined ? Object.assign(task, {departmentIds: saveddepartments.departments}) : Object.assign(task, {departmentIds: R.pluck('id', departments)});
        return task;
    }

    save() {
        this.taskService.putTask(this.task.id, this.updateTask()).subscribe(value => {
            // console.log('Task Updated:', value);
            this.taskService.unlocktask(this.task.id).subscribe(value1 => {
                this.router.navigate(['/grid', 'dispo'], {relativeTo: this.route});
            });
        });

    }

    saveNewInstruction() {
        if (this.newInstructionContent !== '' || !this.newInstructionFile) {
            this.taskService.addInstructions(this.task, new Instruction().deserialize({content: this.newInstructionContent}), this.newInstructionFile).subscribe(
                () => {
                    this.newInstructionContent = '';
                    this.newInstructionFile = undefined;
                    this.taskService.getInstructions(this.task).subscribe((instructions: Instruction[]) => this.instructions = instructions);
                    // console.log('success');
                },
                () => console.log('error')
            );
        }
    }

    saveNewHandbook() {
        if (this.newHandbookContent !== '' || this.newHandbookFile) {
            this.taskService.addHandbook(this.task, new Handbook().deserialize({content: this.newHandbookContent}), this.newHandbookFile).subscribe(() => {
                this.newHandbookContent = '';
                this.newHandbookFile = undefined;
                this.taskService.getHandbooks(this.task).subscribe((handbooks: Handbook[]) => this.handbooks = handbooks);
            });
        }
    }

    deleteHandbook(handbook: Handbook){
        this.taskService.deleteHandbook(handbook.identifier).subscribe(value => {
            this.taskService.getHandbooks(this.task).subscribe((handbooks: Handbook[]) => this.handbooks = handbooks);
        });
    }

    filteredUser(): User[] {
        const {saveddepartments, departments} = this.state;
        const depIds = this.state.departments ? R.pluck('id', departments) : [];

        if ((R.isEmpty(saveddepartments) || saveddepartments === undefined) && R.isEmpty(depIds)) {
            return this.availableUsers;
        }
        let myUsers;
        if (R.isEmpty(saveddepartments) || saveddepartments === undefined) {
            myUsers = R.filter(user => {
                if (isNil(user.departmentId) || R.isEmpty(user.departmentId)) {
                    return true;
                }
                return R.contains(user.departmentId, depIds);
            }, this.availableUsers);
        } else {
            myUsers = R.filter(user => {
                if (isNil(user.departmentId) || R.isEmpty(user.departmentId)) {
                    return true;
                }
                return R.contains(user.departmentId, saveddepartments.departments);
            }, this.availableUsers);
        }
        return myUsers;
    }

    public onNavigateBack() {
        this.taskService.unlocktask(this.task.id).subscribe(value => {
            this.router.navigate(['/grid', 'dispo'], {relativeTo: this.route});
        });
    }

    private onWorkerChanged() {
        if (this.state.worker) {
            // this.state.workplace = this.state.worker.workplace || this.state.workplace;
            this.setState({workplace: this.state.worker.workplace});
        }
    }

    /**
     * detects image change events
     */
    private onFileChanged(file: ImageFile | File) {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => (file as ImageFile).url = reader.result;
            this.newHandbookFile = file;
        }
    }
}
