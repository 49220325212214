import {Deserializable} from '../deserializable.model';
import {Entity} from '../entity.model';

export class ErrorTracking extends Entity implements Deserializable {
    public task_id: string;
    public order_id: string;
    public user_id: string;
    public errorreport_id: string;
    public quantity: string;
    public place: string;
    public step: string;
    public worker: string;
    public priority: number;
    public status: string;
    public erpnumber: string;
    public client: string;
    public error_step: string;
    public error_quantity: string;
    public error_message: string;
    public date: string;
    public resolved: boolean;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

}
