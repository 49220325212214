import {Inject, Injectable, Injector} from '@angular/core';
import {HalService} from './hal.service';
import {HttpClient} from '@angular/common/http';
import {ExternalConfigurationHandlerInterface} from 'angular4-hal';
import {Entity} from '../models/entity.model';

@Injectable({
    providedIn: 'root'
})
export class EntityService extends HalService<Entity> {

    constructor(injector: Injector, httpC: HttpClient, @Inject('ExternalConfigurationService') private exConf: ExternalConfigurationHandlerInterface) {
        super(Entity, injector, httpC, exConf);
    }
}
