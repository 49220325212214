import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {EntityService} from '../services/entity.service';

@Pipe({
    name: 'createFile',
    pure: false
})
export class CreateFilePipe implements PipeTransform {
    private cachedData: any = null;
    private cachedUrl = '';

    constructor(private entityService: EntityService, private sanitizer: DomSanitizer) {
    }

    transform(url: string, ...args: any[]): any {
        if (url !== this.cachedUrl) {
            this.cachedData = null;
            this.cachedUrl = url;
            this.entityService.customQueryGet(url).subscribe((result: string) => this.cachedData = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + result));
        }
        return this.cachedData;
    }

}
