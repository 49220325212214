import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {dataURLtoFile} from '../../helpers/common';
import {ImageFile} from '../edit/edit.component';

@Component({
    selector: 'app-picture',
    templateUrl: './picture.component.html',
    styleUrls: ['./picture.component.scss']
})
export class PictureComponent implements OnInit {

    @Input() multiple?: boolean;

    @Output() fileChanged = new EventEmitter<ImageFile[]>();

    public images: ImageFile[] = [];
    public maxFiles = 3;

    constructor() {
    }

    ngOnInit() {
    }

    // @ts-ignore
    public checkCameraAvailable = (): boolean => navigator.camera;

    onTakePicture() {
        // @ts-ignore
        navigator.camera.getPicture(
            (imageData) => {
                const image = dataURLtoFile('data:image/jpeg;base64,' + imageData, 'CameraPhoto') as ImageFile;
                this.images = [image];
                this.emitChanges(this.images);
            },
            (message) => console.log('Failed because: ' + message),
            {destinationType: 0}
        );
    }

    onFileChanged(files: FileList) {
        Object.keys(files).map((value: string, index: number) => {
            const image = files[index] as ImageFile;
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = () => image.url = reader.result;
            this.multiple ? this.images.push(image) : this.images = [image];
        });

        this.emitChanges(this.images);
    }

    private emitChanges(images: ImageFile[]) {
        images = this.multiple ? images.slice(0, this.maxFiles) : images;
        this.fileChanged.emit(images);
    }
}
