export class HasState {
    state: {};

    constructor() {
    }

    setState(update: {}) {
        Object.assign(this.state, update);
    }
}
