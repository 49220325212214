import { Inject, Injectable, Injector } from '@angular/core';
import { HalService } from './hal.service';
import { Observable, Subject } from 'rxjs';
import { Notification, NotificationService } from './notification.service';
import { HttpClient } from '@angular/common/http';
import { ExternalConfigurationHandlerInterface } from 'angular4-hal';
import { map } from 'rxjs/operators';
import * as R from 'ramda';
import { Role, User } from '../models/entities/user.model';
import { Moment } from 'moment';

@Injectable({
    providedIn: 'root'
})
export class UserService extends HalService<User> {

    public user: User;
    public isAuthenticated: boolean;
    public authenticationChanged = new Subject<boolean>();
    public dateChanged = new Subject<Moment>();

    constructor(injector: Injector, httpC: HttpClient, @Inject('ExternalConfigurationService') private exConf: ExternalConfigurationHandlerInterface, private notificationService: NotificationService) {
        super(User, injector, httpC, exConf);
    }

    public isWorker(): boolean {
        return this.hasRole(Role.Worker);
    }

    public isAdmin(): boolean {
        return this.hasRole(Role.Admin);
    }
    public isQuality(): boolean {
        return this.hasRole(Role.Quality);
    }

    public isQualityOrAdmin(): boolean {
        return this.isAdmin() || this.isQuality();
    }

    public isDispo(): boolean {
        return this.hasRole(Role.Booker) || this.hasRole(Role.Manager);
    }

    public isDispoOrAdmin(): boolean {
        return this.isAdmin() || this.isDispo();
    }

    public canEdit(): boolean {
        return this.isDispoOrAdmin() || this.isQualityOrAdmin();
    }

    public hasRole(role: Role): boolean {
        return this.user && this.user.grantedauthority === role;
    }

    public getRole(): string {
        return this.user ? this.user.grantedauthority : null;
    }

    public getWorkplace(): string {
        return this.user ? this.user.workplace : null;
    }

    public getValidSteps(): number[] {
        return this.user ? this.user.validSteps : [];
    }


    public getId(): string {
        return this.user.id;
    }

    public isFreeUser(): boolean {
        const { departmentEntity } = this.user;
        return departmentEntity === undefined || departmentEntity === null || R.isEmpty(departmentEntity);
    }

    public getUser(uuid: string): Observable<User> {
        return this.customQueryGet('/user/' + uuid, [{ key: 'projection', value: 'detail' }]).pipe(map((source: User) => source));
    }

    public checkAuthenticationStatus(): Promise<any> {
        return this.customQueryGet('fe/checkLogin')
            .toPromise()
            .then((user: User) => {
                this.user = user;
                this.isAuthenticated = true;
            }).catch(() => {
                this.user = null;
                this.isAuthenticated = false;
            }).finally(() => this.authenticationChanged.next(this.isAuthenticated));
    }

    public login(name: string, pwd: string): Promise<any> {
        return this.customPost('fe/login', { username: name, password: pwd })
            .toPromise()
            .then(() => this.checkAuthenticationStatus())
            .catch(() => this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.error.credentials'), null, 'error', 2000, false)));
    }

    public logout(): Observable<any> {
        this.clear();
        return this.customQueryGet('fe/logout');
    }

    public clear() {
        this.user = null;
        this.isAuthenticated = false;
        this.authenticationChanged.next(false);
    }
}
