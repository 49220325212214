import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], searchMap: string[], operator: 'and' | 'or' | 'not' = 'or'): any[] {
        if (!items) {
            return [];
        }
        if (!searchMap.length || !items.length) {
            return items;
        }
        if (operator === 'not') {
            return items.filter(item => searchMap.every(text => JSON.stringify(Object.values(item)).toLowerCase().indexOf(text.toLowerCase()) === -1));
        } else {
            return items.filter(item => searchMap[operator === 'and' ? 'every' : 'find'](text => JSON.stringify(Object.values(item)).toLowerCase().indexOf(text.toLowerCase()) !== -1));
        }
    }

}
