import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'replace'
})
export class ReplacePipe implements PipeTransform {

    transform(text: string, searchValue: string = ';', newValue: string = '<br/>'): string {
        return text.replace(new RegExp(searchValue, 'g'), newValue);
    }
}
