import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {delay, startWith, tap} from 'rxjs/operators';
import {UserService} from '../../services/user.service';
import {NotificationService} from '../../services/notification.service';
import {User} from '../../models/entities/user.model';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
    @Output() toggleSideNav: EventEmitter<any> = new EventEmitter();
    @Input() visible: boolean;
    @Input() user: User;
    @Input() collapsed: boolean;
    public isBuffering = false;

    constructor(private notificationService: NotificationService, private userService: UserService) {
        this.setUser();
        this.userService.authenticationChanged.subscribe((isAuthenticated: boolean) => isAuthenticated ? this.userService.getUser(this.userService.user.id).subscribe((user: User) => this.user = user) : this.user = null);

        this.notificationService.isBuffering.pipe(
            startWith(null),
            delay(0),
            tap((isBuffering: boolean) => this.isBuffering = isBuffering)
        ).subscribe();
    }

    ngOnInit() {
    }

    private setUser() {
        this.userService.user ? this.userService.getUser(this.userService.user.id).subscribe((user: User) => this.user = user) : this.user = null;
    }

}
