import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    private readonly returnUrl: string;
    private isAuthenticated: boolean = this.userService.isAuthenticated;
    protected form: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required]),
        password: new FormControl('', Validators.required),
    });

    constructor(private route: ActivatedRoute, private router: Router, private userService: UserService) {
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
        this.userService.authenticationChanged.subscribe((isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated);
    }

    ngOnInit() {
        // redirect to home if already logged in
        if (this.isAuthenticated) {
            this.router.navigate([this.returnUrl]);
        }
    }

    private onSubmit() {
        if (this.form.valid) {
            this.userService.login(this.form.value.email, this.form.value.password).then(() => this.router.navigate([this.returnUrl]));
        }
    }
}
