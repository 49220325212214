import {Deserializable} from '../deserializable.model';
import {Entity} from '../entity.model';

export class ResourceEntity {
    public contenttype: string;
    public created: string;
    public filename: string;
    public id: string;
    public owner: string;
    public path: string;
    public version: number;
}

export class Instruction extends Entity implements Deserializable {
    public client: string;
    public content: string;
    public erpnumber: string;
    public major: boolean;
    public resourcesEntity: ResourceEntity;
    public selectionorder: number;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    getClean() {
        return {
            client: this.client,
            content: this.content,
            erpnumber: this.erpnumber,
            major: this.major,
            resourcesEntity: this.resourcesEntity,
            selectionorder: this.selectionorder
        };
    }
}
