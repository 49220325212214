import {Inject, Injectable, Injector} from '@angular/core';
import {HalService} from './hal.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ExternalConfigurationHandlerInterface} from 'angular4-hal';
import * as R from 'ramda';
import {Task} from '../models/entities/task.model';
import {ConfigList} from '../models/entities/config-list.model';

@Injectable({
    providedIn: 'root'
})

export class ConfigService extends HalService<ConfigList> {

    public config: Observable<ConfigList>;
    private defaultConfig: ConfigList = new ConfigList().deserialize({displaycurrentstep: 'true', displaydonetab: 'false', displayscrapbutton: 'false', displayshop: 'true', disponentnext: 'false', extendtileinformation: 'true', fertigungsleiteremail: 'e.arnold@soul-surf.com', filterbysteps: 'false', filterlastworkstep: 'false', labelconfig: '[\"Express\", \"Zuverlässig\", \"Abholung\"]', noworkplace: 'false', prioritystep: '10000', qualityemail: 'e.arnold@soul-surf.com', randomworksteps: 'false', timea: '3', timeb: '11', timec: '4'});

    constructor(injector: Injector, httpC: HttpClient, @Inject('ExternalConfigurationService') private exConf: ExternalConfigurationHandlerInterface) {
        super(ConfigList, injector, httpC, exConf);
    }

    init() {
        this.config = new Observable<any>(subscriber => {
            this.customQueryGet('/clientConfig/list').subscribe(value => {
                subscriber.next(Object.assign(this.defaultConfig, value));
                subscriber.complete();
            });
        });
    }

    public getClientConfig(): Observable<ConfigList> {
        return this.get('clientConfig/list');
    }

    public getAppConfig(): Observable<ConfigList> {
        return new Observable<ConfigList>(subscriber => this.config.subscribe(value => subscriber.next(value)));
    }

    public getLabels(): Observable<string[]> {
        return new Observable<string[]>(subscriber => this.config.subscribe(value => subscriber.next(JSON.parse(value.labelconfig))));
    }

    public getFilterLastWorkStep(): Observable<boolean> {
        return new Observable<boolean>(subscriber => this.config.subscribe(value => subscriber.next(value.filterlastworkstep === 'true')));
    }

    public showTime(task: Task): Observable<{ time: string }> {
        return new Observable<any>(subscriber => {
            this.config.subscribe(value => {
                const {timea, timeb, timec} = value;
                if (task.step === timea && !R.isEmpty(task.timea)) {
                    subscriber.next({time: task.timea});
                } else if (task.step === timeb && !R.isEmpty(task.timeb)) {
                    subscriber.next({time: task.timeb});
                } else if (task.step === timec && !R.isEmpty(task.timec)) {
                    subscriber.next({time: task.timec});
                }
                subscriber.next({time: '-'});
            });
        });
    }
}
