import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { HalParam } from 'angular4-hal';
import { Notification, NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS, AppDateAdapter } from '../../helpers/format-datepicker';
import { from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ResourceService } from 'angular4-hal/src/resource.service';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { Property } from '../../models/profile.model';
import { AutoCompleteOptions, Entity, Relation } from 'src/app/models/entity.model';
import { Link, Result } from '../../models/result.model';
import { isNil } from '../../helpers/common';
import { EntityService } from '../../services/entity.service';

export class ImageFile extends File {
    constructor(public fileBits: BlobPart[], public fileName: string, public url: string | ArrayBuffer, public resource: string, public options?: FilePropertyBag) {
        super(fileBits, fileName, options);
    }
}

@Component({
    selector: 'app-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class EditComponent implements OnInit {
    @Input() entityPath: string;
    @ViewChild('changePasswordDialog', { static: true }) changePasswordDialog: TemplateRef<any>;

    public enlargeImage: boolean[] = [];
    public tools: object = {
        items: [
            'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
            'FontSize', 'FontColor', 'BackgroundColor', '|',
            'Undo', 'Redo', '|',
            'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
            'Indent', 'Outdent', '|', 'SourceCode']
    };
    public path: string;
    public editMode: boolean;
    public identifier: string;
    public url: string;
    public fields: Property[] = [];
    public entity: Entity;
    public relations: any[] = [];
    private defaultRelations: any[] = [];
    public selectors: any[] = [];
    private filter: string[] = [];
    public requiredFields: string[] = [];
    public minimumPasswordLength = 4;
    public selectedFile: File;
    public selectedImages: ImageFile[] = [];
    public maxFileSize = 5242880; // 5MB
    public autocomplete: any[] = [];
    private autocompleteValue = '';
    public pattern = {
        email: '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
    };
    public accept = { image: ['image/jpeg', 'image/png'], csv: ['.csv', 'text/csv', 'application/vnd.ms-excel'], pdf: ['application/pdf'] };
    public options: { [name: string]: AutoCompleteOptions } = {
        client: { path: 'order', key: 'client', value: 'client', size: 20 },
        companyName: { path: 'order', key: 'client', value: 'client', size: 20 },
        erpnumber: { path: 'task', key: 'erpnumber', value: 'erpnumber', size: 20 },
        erpnumberSmall: { path: 'task', key: 'erpnumberSmall', value: 'erpnumberSmall', size: 20 },
        clientNumber: { path: 'task', key: 'clientNumber', value: 'clientNumber', size: 20 },
        worker: { path: 'user', key: 'firstname', value: 'fullname', size: 20 },
        // owningTask: {path: 'task', key: 'description', value: 'id', size: 20, display: 'description'}
    };

    constructor(private route: ActivatedRoute, private router: Router, private entityService: EntityService, private notificationService: NotificationService, public userService: UserService, private dialog: MatDialog, private resource: ResourceService, private location: Location, private cdRef: ChangeDetectorRef) {
        this.notificationService.isLoading.next(true);
        this.filter = environment.filter.edit.excludes;

        // override workplaces
        this.entityService.get('workplace', [{ key: 'size', value: 100 }]).subscribe((workplaces: Result) => workplaces.page.totalElements > 0 ? environment.relation.workplace = workplaces._embedded.workplace.map(workplace => workplace.name) : null);

    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.path = params.path || this.entityPath;
            this.identifier = params.identifier || null;
            this.editMode = !!this.identifier;
            this.url = this.path + '/' + this.identifier;

            // set required fields
            this.requiredFields = environment.required[this.path] || [];
            // get field properties and load data
            this.entityService.getFieldProperties(this.path).subscribe(
                (properties: { [name: string]: Property }) => this.fields = Object.keys(properties).map((k) => properties[k]),
                () => '',
                () => {
                    // console.log('Fluff:', this.editMode);
                    if (this.editMode) {
                        this.entityService.get(this.url, [{ key: 'projection', value: 'backend' }]).subscribe(
                            (entity: Entity) => this.entity = entity,
                            (error: HttpErrorResponse) => console.log(error),
                            () => this.initData()
                        );
                    } else {
                        this.entity = new Entity();
                        this.initData();
                    }
                }
            );
        });
    }

    private initData() {

        // filter or hide some fields
        this.fields = this.fields.filter((field) => !this.filter.includes(field.key));

        // add password field to user
        if (this.path === 'user') {
            this.fields.push({ title: 'Password', readOnly: false, type: 'password', key: 'password', value: '', visible: true });
        }

        this.fields.map((field: Property) => {

            const fieldType = field.type;
            const fieldName = field.key;

            // override field type if necessary and set projections to read only!
            const link: Link | boolean = this.entity._links ? this.entity._links[fieldName] : false;
            field.value = this.entity[fieldName] || '';
            field.readOnly = this.userService.canEdit() ? link && link.templated ? link.templated : field.readOnly : true;

            // hide read only as well as complex fields when create a new entity
            field.visible = ['csv', 'pdf', 'image', 'textarea'].includes(fieldType) || field.readOnly ? this.editMode : true;

            // show password only when create a new user
            field.visible = fieldType === 'password' ? !this.editMode : field.visible;

            if (['ManyToManyRelation', 'ManyToOneRelation'].includes(fieldType)) {

                // hide relation fields when create a new entity
                field.visible = this.editMode;

                // get possible relation values
                const parameter: HalParam[] = [{ key: 'size', value: 100 }];
                const path = environment.relation[fieldName].path;
                this.selectors[fieldName] = environment.relation[fieldName].selector;
                this.entityService.get(path, parameter).subscribe((relationEntity: Result) => this.relations[fieldName] = relationEntity._embedded[path]);

                // get selected relation values
                // @ts-ignore
                this.entity[fieldType === 'ManyToOneRelation' ? 'getRelation' : 'getRelationArray'](Relation, fieldName).subscribe((relation: Relation | Relation[]) => {
                    this.entity[fieldName] = relation;
                    this.defaultRelations[fieldName] = relation; // to compare what data has been added, updated or deleted
                });
            }
        });

        // prevent "Expression has changed after it was checked"-Error
        this.cdRef.detectChanges();

        this.notificationService.isLoading.next(false);
    }

    /**
     * Save and update data
     */
    public onSubmit() {
        const entity = { ...this.entity } as Entity;
        const translatedPath = this.notificationService.translate.instant(this.path);

        if (this.editMode) {

            Object.keys(this.relations).map((relationName: string) => {

                if (!isNil(this.entity[relationName])) {
                    /**
                     * for ManyToManyRelations -> loop through all relation items
                     * first loop: delete all
                     * second loop: add all selected
                     */
                    if (Array.isArray(this.entity[relationName])) {
                        from(this.defaultRelations[relationName]).pipe(concatMap((relation: Entity) => this.entity.deleteRelation(relationName, relation))).subscribe(
                            () => '',
                            err => console.log(err),
                            () => from(this.entity[relationName]).pipe(concatMap((relation: Entity) => this.entity.updateRelation(relationName, relation))).subscribe()
                        );
                    } else {
                        /**
                         * for ManyToOneRelations -> just add (override) the new relation
                         */
                        this.entity.addRelation(relationName, this.entity[relationName]).subscribe();
                    }
                }
                // delete all relations from entity copy -> otherwise the update will fail
                delete entity[relationName];
            });

            // at last update the entity itself
            this.entityService.patch(entity).subscribe(
                () => this.manageFileUpload(),
                (error: HttpErrorResponse) => this.notificationService.addNotification(new Notification(this.notificationService.translate.instant(error.error.message || error.message), this.url, 'error', 5000)),
                () => {
                    this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.successfully.updated', { value: translatedPath }), this.url, 'success'));
                    this.onNavigateBack();
                });
        } else {
            // create entity
            this.resource.create(this.entityPath, entity as Entity).subscribe(
                (newEntity: Entity) => {
                    this.dialog.closeAll();
                    this.identifier = (newEntity._links.self.href).split('/').slice(-1)[0];
                },
                (error: HttpErrorResponse) => this.notificationService.addNotification(new Notification(this.notificationService.translate.instant(error.error.message || error.message), this.url, 'error', 5000)),
                () => {
                    this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.successfully.created', { value: translatedPath }), this.url, 'success'));
                    this.router.navigate([this.entityPath + '/' + this.identifier]);
                });
        }
    }

    /**
     * manages file uploads
     */
    private manageFileUpload() {
        const url = this.path === 'csvupload' ? this.url + '/upload' : 'upload/' + this.url;
        if (this.selectedFile) {
            const uploadData = new FormData();
            uploadData.append('file', this.selectedFile, this.selectedFile.name);
            this.uploadFile(uploadData, url);
        }

        if (Object.keys(this.selectedImages).length) {
            const uploadData = new FormData();
            Object.keys(this.selectedImages).map((key: string) => {
                const fileName = this.path === 'errorreport' && key === 'resourcesEntity' ? 'file_1' : (key === 'picture_2' ? 'file_2' : (key === 'picture_3' ? 'file_3' : 'file'));
                this.selectedImages[key] ? uploadData.append(fileName, this.selectedImages[key], this.selectedImages[key].name) : uploadData.append(fileName, '');
            });
            this.uploadFile(uploadData, url);
        }
    }

    /**
     * uploads files
     */
    private uploadFile(body: {}, path: string) {
        this.entityService.customPost(path, body).subscribe(
            () => this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.successfully.uploaded'), this.url, 'success', 5000, false)),
            (error: HttpErrorResponse) => this.notificationService.addNotification(new Notification(error.error.message || error.message, this.url, 'error', 10000))
        );
    }

    /**
     * Change Password for a user
     */
    public onChangePassword() {
        this.dialog.open(this.changePasswordDialog, { data: { password: '', rpt_password: '' } }).afterClosed().subscribe(
            (result: { password: string, rpt_password: string }) => {
                if (result && result.password && result.password !== '' && result.password.length >= this.minimumPasswordLength && this.userService.isAdmin()) {
                    this.entityService.customPut(this.url + '/resetpassword', { password: result.password }).subscribe(
                        () => '',
                        (error: HttpErrorResponse) => this.notificationService.addNotification(new Notification(error.error.message || error.message, this.url, 'error', 5000)),
                        () => this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.successfully.updated', { value: 'Passwort' }), this.url, 'success'))
                    );
                }
            });
    }

    /**
     * detects image change events
     */
    public onImageChanged(file: ImageFile | File, resource: string) {

        if (!file) {
            this.selectedImages = this.selectedImages.filter((image: ImageFile) => image.resource !== resource);
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            (file as ImageFile).url = reader.result;
            (file as ImageFile).resource = resource;
        };
        this.selectedImages[resource] = file;
    }

    /**
     * returns the first error on an input field
     */
    public onGetErrorCode(formControl: NgForm, name: string) {
        const errors = formControl.control.get(name).errors || {};
        return Object.keys(errors)[0] || false;
    }

    /**
     * detects file change events
     */
    public onFileChanged(file: File) {
        this.selectedFile = file;
    }

    /**
     * navigate back to previous page
     */
    public onNavigateBack() {
        this.location.back();
    }

    /**
     * save date as iso string
     */
    public onChangeDate(event: MatDatepickerInputEvent<string>, key: string) {
        this.entity[key] = moment(event.value).toISOString(true);
    }

    /**
     * save textarea value as (real) html string
     */
    public onChangeTextArea(value: string, key: string) {
        this.entity[key] = value;
    }

    /**
     * compares possible select options with indeed selected
     */
    public objectComparisonFunction = (option, value): boolean => option && value && option._links.self.href === value._links.self.href;

    /**
     * returns the url of an file
     */
    public getFileURL(endpoint: string): string {
        return this.entityService.rootUri + this.url + '/' + endpoint;
    }

    /**
     * returns select option array
     */
    public onGetSelectOptions(name: string): string[] {
        return environment.relation[name] || [];
    }

    public onAutoComplete(value: string, name: string) {
        if (value.trim() === '') {
            this.entity[name] = '';
            return this.autocomplete[name] = [];
        }

        if (value !== this.autocompleteValue) {
            this.autocompleteValue = value;
            this.entityService.executeDslQuery(value, this.options[name]).subscribe((result: any[]) => this.autocomplete[name] = result);
        }
    }

}
