import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TaskService } from 'src/app/services/task.service';
import { Task } from 'src/app/models/entities/task.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SortOrder } from 'angular4-hal/src/sort';
import { HttpErrorResponse } from '@angular/common/http';
import { HalOptions } from 'angular4-hal';
import { forkJoin, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import 'moment/locale/de';

@Component({
  selector: 'app-find',
  templateUrl: './find.component.html',
  styleUrls: ['./find.component.scss']
})
export class FindComponent implements OnInit, AfterViewInit {

  private searchUrl = 'task/search/dslquery';
  private searchParams: string[] = ['client', 'erpnumber', 'orderNumber', 'date'];

  public searchString: string;
  public displayedColumns: string[] = [...this.searchParams, ...['quantity', 'status', 'deliveryDate', 'worker']];
  public dataSource = new MatTableDataSource([]);
  public isLoading: boolean;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchValue', { static: true }) searchValue: ElementRef;


  constructor(private router: Router, private taskService: TaskService) { }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;

    this.sort.sortChange.subscribe(
      (sort: { active: string, direction: 'asc' | 'desc' }) => this.search(sort.active, (sort.direction).toUpperCase() as SortOrder)
    );
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchValue.nativeElement, 'keyup').pipe(debounceTime(100)).subscribe((event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        const filterValue = ((event.target as HTMLInputElement).value || '').trim();
        if (filterValue.length && filterValue !== this.searchString) {
          this.searchString = filterValue;
          this.search();
        }
      }
    });
  }

  public onNavigateTo(id: string): void {
    this.router.navigate(['detail/dispo', id]);
  }

  private search(sortBy: string = 'date', sortOrder: SortOrder = 'DESC') {
    this.isLoading = true;
    const requests = [];

    this.searchParams.forEach((column: string) => {
      let searchQuery: string;

      if (['date', 'deliveryDate'].includes(column)) {
        const searchDate = moment(this.searchString, ['DD.MM', 'DD.MM.YY', 'DD.MM.YYYY', 'DDMM', 'DDMMYY', 'DDMMYYYY'], 'de');
        searchQuery = searchDate.isValid() ? column + '>' + searchDate.startOf('day').toISOString(true) + ',' + column + '<' + searchDate.endOf('day').toISOString(true) : '';
      } else {
        searchQuery = column + '%' + this.searchString;
      }

      if (searchQuery !== '') {
        const options: HalOptions = { size: 100, sort: [{ path: sortBy, order: sortOrder }], params: [{ key: 'search', value: searchQuery }] };
        requests.push(this.taskService.customQuery(this.searchUrl, options));
      }

    });

    forkJoin(requests).subscribe(
      (results: [Task[]]) => this.dataSource = new MatTableDataSource([].concat(...results).filter(function ({ id }) { return !this[id] && (this[id] = id) }, {})),
      (error: HttpErrorResponse) => this.isLoading = false,
      () => this.isLoading = false
    );
  }

}
