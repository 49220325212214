import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Notification, NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { EntityService } from '../../services/entity.service';
import { environment } from '../../../environments/environment';
import { Moment } from 'moment';
import * as moment from 'moment';
import { HalOptions } from 'angular4-hal';
import { forkJoin, Observable } from 'rxjs';
import { Task } from 'src/app/models/entities/task.model';
import { ErrorTracking } from 'src/app/models/entities/error-tracking.model';
import { TaskService } from 'src/app/services/task.service';
import { ErrorTrackingService } from 'src/app/services/error-tracking.service';

export class NavigationItem {
    constructor(public name: string, public access: boolean, public route?: string, public badge?: number, public icon?: string) {
    }
}

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
    @Output() closeSideNav: EventEmitter<any> = new EventEmitter();
    @Output() collapseSideNav: EventEmitter<any> = new EventEmitter();
    @Input() collapsed: boolean;

    public links: NavigationItem[] = [];
    private notifications: string = this.notificationService.notifications.length.toString();

    private dateFormat = 'DD-MM-YYYY';
    private currentDate: string = moment().weekday(0).format(this.dateFormat);

    constructor(private entityService: EntityService, private notificationService: NotificationService, private userService: UserService, private router: Router, private taskService: TaskService, private errorService: ErrorTrackingService) {
        this.notificationService.notificationsChanged.subscribe((notifications: Notification[]) => this.notifications = notifications.length.toString());

        this.userService.dateChanged.subscribe((date: Moment) => {
            if (date.format(this.dateFormat) !== this.currentDate) {
                this.currentDate = date.format(this.dateFormat);
                this.initTaskCounter();
            }
        });
    }

    ngOnInit() {
        this.entityService.getRootLinks().subscribe(
            (links: string[]) => this.links = [...['calendar', 'find'], ...this.includes(links)].map((link: string) => ({ name: link, access: this.hasAccess(link) })),
            (error) => console.log(error),
            () => {
                ['dispo', 'quality', 'in-progress', 'reserved', 'shop'].map((link: string) => this.links.unshift({ name: link === 'reserved' ? 'in-pool' : link, route: 'grid/' + link, access: this.hasAccess(link) }));
                this.initTaskCounter();
            }
        );
    }

    private includes = (links: string[]) => links.filter((key: string) => environment.filter.sidenav.includes.includes(key));
    private hasAccess = (key) => !(environment.access && environment.access[key] && environment.access[key].indexOf(this.userService.user.grantedauthority) === -1);

    public onLogout() {
        this.router.navigate(['/dashboard'], {}).then(value => {
            this.userService.logout().subscribe(
                () => this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } })
            );
        });

    }

    private initTaskCounter() {
        // set start and end date
        const date = moment(this.currentDate, this.dateFormat);
        const start = date.weekday(0).hour(0).minute(0).second(0).millisecond(0).toISOString(true);
        const end = date.weekday(6).hour(0).minute(0).second(0).millisecond(0).toISOString(true);
        const query = [`date>${start}`, `date<${end}`];

        const options: HalOptions = { size: 1000, params: [{ key: 'search', value: query.join() }, { key: 'projection', value: 'details' }] };
        const requests: [Observable<Task[]>, Observable<ErrorTracking[]>?] = [this.taskService.customQuery(`task/search/dslquery`, options)];

        if (this.userService.isQualityOrAdmin()) {
            requests.push(this.errorService.customQuery(`errortracking/search/dslquery`, options));
        }

        forkJoin(requests).subscribe(
            ([tasks, errorTrackings]: [Task[], ErrorTracking[]]) => {

                const dateString = '/' + date.isoWeekYear() + '/' + date.isoWeek();
                tasks = this.userService.isDispoOrAdmin() ? tasks : tasks.filter((task: Task) => task.userId === this.userService.getId());

                // shop
                this.links.find((link: NavigationItem) => link.name === 'shop').badge = tasks.length;
                this.links.find((link: NavigationItem) => link.name === 'shop').route = 'grid/shop' + dateString;

                // reserved
                this.links.find((link: NavigationItem) => link.name === 'in-pool').badge = tasks.filter((task: Task) => task.status === 'reserved' && task.userId === this.userService.getId()).length;
                this.links.find((link: NavigationItem) => link.name === 'in-pool').route = 'grid/reserved' + dateString;

                // in-progress
                this.links.find((link: NavigationItem) => link.name === 'in-progress').badge = tasks.filter((task: Task) => task.status === 'in-progress' && task.userId === this.userService.getId()).length;
                this.links.find((link: NavigationItem) => link.name === 'in-progress').route = 'grid/in-progress' + dateString;

                // quality
                this.links.find((link: NavigationItem) => link.name === 'quality').badge = errorTrackings ? errorTrackings.length : 0;
                this.links.find((link: NavigationItem) => link.name === 'quality').route = 'grid/quality' + dateString;

                // disponent
                this.links.find((link: NavigationItem) => link.name === 'dispo').badge = tasks.length;
                this.links.find((link: NavigationItem) => link.name === 'dispo').route = 'grid/dispo' + dateString;

            });
    }
}
