import {Deserializable} from '../deserializable.model';
import {Entity} from '../entity.model';
import {Department} from './department.model';

export enum Role {
    Worker = 'ROLE_WORKER',
    Admin = 'ROLE_ADMIN',
    Quality = 'ROLE_QA',
    Booker = 'ROLE_DISPO',
    Manager = 'ROLE_OPMANAGER',
}


export class User extends Entity implements Deserializable {
    public active: boolean;
    public avatar: {};
    public companyName: string;
    public departmentEntity: Department;
    public email: string;
    public firstname: string;
    public grantedauthority: Role;
    public id: string;
    public identnumber: string;
    public lastname: string;
    public title: string;
    public workplace: string;
    public department?: string;

    public fullname: string;
    public validSelectionorders: number[];
    public validSteps: number[];

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

}
