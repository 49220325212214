import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as moment from 'moment';
import { Moment } from 'moment';
import 'moment/locale/de';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatChipInputEvent, MatDatepickerInputEvent, MatDialog } from '@angular/material';
import { Notification, NotificationService } from '../../services/notification.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { TaskService } from 'src/app/services/task.service';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user.service';
import { ConfigService } from '../../services/config.service';
import * as R from 'ramda';
import { isNil, nameForWorkstep } from '../../helpers/common';
import { ConfigList } from '../../models/entities/config-list.model';
import { Task } from 'src/app/models/entities/task.model';
import { Order } from 'src/app/models/entities/order.model';
import { Entity } from '../../models/entity.model';
import { ErrorTracking } from '../../models/entities/error-tracking.model';
import { WorkStep } from '../../models/entities/workstep.model';
import { ErrorTrackingService } from '../../services/error-tracking.service';
import { EntityService } from '../../services/entity.service';
import { AppComponent } from '../../app.component';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

    @ViewChild('picker', { static: false }) datePicker: MatDatepicker<Date>;
    @ViewChild('selectItemsDialog', { static: true }) selectItemsDialog: TemplateRef<any>;
    @ViewChild('filterDialog', { static: true }) filterDialog: TemplateRef<any>;
    @ViewChild('reservedDialog', { static: true }) reservedDialog: TemplateRef<any>;

    // configs
    public isDispoOrAdmin: boolean = this.userService.isDispoOrAdmin();
    public limit = +localStorage.getItem('limit') || 10;
    public maxLimit = 1000;
    public showWeekend = localStorage.getItem('showWeekend') === 'true';
    public filterLastWorkstep = localStorage.getItem('filterLastWorkstep') === 'true';
    public filterSteps = localStorage.getItem('filterSteps') === 'true';
    public filterCompletedSteps = localStorage.getItem('filterCompletedSteps') === 'true';
    public currentUserId = null;
    public filter: string[] = [];
    public filterOperator: 'and' | 'or' | 'not' = 'and';
    readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

    public actionsDisabled = false;
    public fullscreen = false;
    public mode = 'shop';
    public gridHeaderText: { headline: string, description: string, icon: string } = { headline: 'grid', description: 'grid description', icon: 'icon.grid' };

    // order setting
    public defaultSortValue = 'priority';
    public sortByValue = localStorage.getItem('sortByValue') || this.defaultSortValue;
    public reverse = localStorage.getItem('reverse') === 'true';
    private filterValues: { userId: string, freeUser: boolean, userDepartment: any, userSteps: any, workstepCount: number, errorSteps: WorkStep[] } = { userId: '', freeUser: true, userDepartment: {}, userSteps: [], workstepCount: 0, errorSteps: [] };

    // dates
    public dateFormat = 'dd.MM.yyyy';
    public dayFormat = 'EEEE';
    private today = moment().format();
    private selectedDate: Moment = moment().weekday(0);
    private year: number = this.selectedDate.year();
    private week: number = this.selectedDate.week();

    // data
    private entityCount: number;
    private priorityStep: number;
    private workStepCount: number;
    private workstep: WorkStep[];
    public entities: { date: string, tasks: Task[], errors: ErrorTracking[] }[] = [];
    private optionalTaskItems = [{ name: 'labels' }, { name: 'departmentEntityList' }, { name: 'status' }, { name: 'originalQuantity' }, { name: 'place' }, { name: 'step' }, { name: 'client' }, { name: 'priority' }, { name: 'timea' }, { name: 'timeb' }, { name: 'timec' }, { name: 'description' }, { name: 'owningTaskDescription' }, { name: 'orderNumber' }, { name: 'owningTaskErpNumber' }, { name: 'completedWorksteps' }, { name: 'taskLock' }];
    private optionalErrorItems = [{ name: 'quantity' }, { name: 'place' }, { name: 'step' }, { name: 'worker' }, { name: 'priority' }, { name: 'status' }, { name: 'erpnumber' }, { name: 'client' }, { name: 'error_step' }, { name: 'error_quantity' }, { name: 'error_message' }, { name: 'date' }, { name: 'scrap' }];
    private optionalItems = R.clone(this.optionalTaskItems);
    private selectedOptions: string[] = localStorage.getItem('taskOptions') !== null ? localStorage.getItem('taskOptions').split(',') : this.optionalItems.map((item: { name: string }) => item.name);

    constructor(private breakpointObserver: BreakpointObserver, private notificationService: NotificationService, private dialog: MatDialog, private route: ActivatedRoute, private router: Router, private entityService: EntityService, private taskService: TaskService, private errorService: ErrorTrackingService, private userService: UserService, private configService: ConfigService, public app: AppComponent) {
        this.configService.getClientConfig().subscribe((list: ConfigList) => this.priorityStep = +list.prioritystep || 10000);
        this.app.onToggleMode('side');
        breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe((state: BreakpointState) => {
            switch (state.matches) {
                case breakpointObserver.isMatched(Breakpoints.XSmall):
                    this.dateFormat = '';
                    this.dayFormat = 'EE';
                    return;
                case breakpointObserver.isMatched(Breakpoints.Small):
                    this.dateFormat = 'dd.MM.';
                    this.dayFormat = 'EE';
                    return;
                case breakpointObserver.isMatched(Breakpoints.Medium):
                    this.dateFormat = 'dd.MM.yy';
                    this.dayFormat = 'EE';
                    return;
                case breakpointObserver.isMatched(Breakpoints.Large):
                    this.dateFormat = 'dd.MM.yyyy';
                    this.dayFormat = 'EEEE';
                    return;
                case breakpointObserver.isMatched(Breakpoints.XLarge):
                    this.dateFormat = 'dd.MM.yyyy';
                    this.dayFormat = 'EEEE';
                    return;
            }
        });

        // set live time clock
        setInterval(() => this.today = moment().format(), 10000);
    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {

            // show loading indication
            this.notificationService.isLoading.next(true);

            // set mode and header text
            this.mode = !isNil(params.mode) && !R.isEmpty(params.mode) ? params.mode : 'shop';
            Object.assign(this.gridHeaderText, { headline: 'grid_headline.' + this.mode, description: 'grid_description.' + this.mode, icon: 'icon.' + this.mode });

            this.currentUserId = this.userService.getId();

            if (params.week && params.year) {
                this.selectedDate = moment().year(params.year).week(params.week).weekday(0);

                // set global date for sidenave badges
                this.userService.dateChanged.next(this.selectedDate);

                this.week = params.week;
                this.year = params.year;
            }
            this.initFilterParams().subscribe(() => this.initGrid());
        });
    }

    // handle reserve task click
    public onGivebackTask(task: Task) {
        this.taskService.freeTask(task.id, task).subscribe();
        this.onRefresh();
    }

    // handle execute task click
    public onExecuteTask(task: Task) {
        Object.assign(task, { status: 'in-progress' });
        this.taskService.reserveTask(task.id, task).subscribe();
        this.router.navigate(['/detail', 'in-progress', task.routes.uuid], { relativeTo: this.route });
    }

    // disables weekend on datepicker
    public disableWeekend(d: Date) {
        if (d.getDay() !== 0 && d.getDay() !== 6) {
            return d;
        }
    }

    // set filters and work steps
    private initFilterParams() {
        return new Observable(subscriber => {
            const { user } = this.userService;
            const diff = (a, b) => a - b;
            this.filterValues.userId = this.userService.getId();
            this.filterValues.freeUser = this.userService.isFreeUser();
            this.taskService.getErrorWorkSteps().subscribe(value => this.filterValues.errorSteps = value);
            // @ts-ignore
            this.filterValues.userSteps = user.validSteps != null ? R.dropLast(1, R.sort(diff, R.concat(R.map((step) => R.subtract(step, 1), user.validSteps), user.validSteps))) : [];
            this.filterValues.userDepartment = user.departmentEntity;
            this.configService.getAppConfig().subscribe((config: ConfigList) => {
                // this.filterValues.filterLastWorkstep = config.filterlastworkstep === 'true';
                // this.filterValues.filterSteps = config.filterbysteps === 'true';
                this.taskService.getAllWorkSteps().subscribe((workSteps: WorkStep[]) => {
                    this.workstep = workSteps;
                    this.workStepCount = workSteps.length;
                    subscriber.next(this.filterValues);
                    subscriber.complete();
                });
            });
        });
    }

    // init data an initialize
    private initGrid() {

        this.entities = []; // reset data
        [0, 1, 2, 3, 4, 5, 6].map((idx: number) => this.entities.push({
            // create empty week array
            date: this.selectedDate.weekday(idx).format(),
            tasks: [],
            errors: []
        }));

        // set start and end date
        const start = this.selectedDate.weekday(0).hour(0).minute(0).second(0).millisecond(0).toISOString(true);
        const end = this.selectedDate.weekday(6).hour(0).minute(0).second(0).millisecond(0).toISOString(true);

        // create url query params
        const query = [`date>${start}`, `date<${end}`];
        this.mode === 'quality' ? this.initErrorGrid(query) : this.initTaskGrid(query);
    }

    // initialize errors to display on grid
    private initErrorGrid(query: string[]) {

        this.optionalItems = R.clone(this.optionalErrorItems);
        this.selectedOptions = localStorage.getItem('errorOptions') !== null ? localStorage.getItem('errorOptions').split(',') : this.optionalErrorItems.map((item: { name: string }) => item.name);

        this.errorService.customQuery(`errortracking/search/dslquery`, {
            size: 1000,
            sort: [{ path: 'erpnumber', order: 'ASC' }],
            params: [{ key: 'search', value: query.join() }, { key: 'projection', value: 'details' }]
        }).subscribe((errorTrackings: ErrorTracking[]) => {
            this.entityCount = errorTrackings.length;
            // if no data available, return empty entities
            if (!this.entityCount) {
                this.notificationService.isLoading.next(false);
                // this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('no data available'), null, 'info', 2000, false));
                return this.entities;
            }
            errorTrackings.map((errorTracking: ErrorTracking) => {
                const index = +errorTracking.moment.format('d') - 1;
                if (index >= 0) {
                    const priority = +(1 + this.entities[+errorTracking.moment.format('d') - 1].tasks.length) * this.priorityStep;
                    errorTracking.priority = errorTracking.priority || priority;
                    this.entities[+errorTracking.moment.format('d') - 1].errors.push(errorTracking);
                }
            });
            // sort all entities by priority
            this.sortEntitiesBy('priority');
            this.notificationService.isLoading.next(false);
        });
    }

    // initialize tasks to display on grid
    private initTaskGrid(query: string[]) {

        this.optionalItems = R.clone(this.optionalTaskItems);
        this.selectedOptions = localStorage.getItem('taskOptions') !== null ? localStorage.getItem('taskOptions').split(',') : this.optionalTaskItems.map((item: { name: string }) => item.name);

        if (['reserved', 'in-progress'].includes(this.mode)) {
            query.push(`status:${this.mode}`);
            query.push(`userId§${this.userService.getId()}`);
        }

        // creat http request
        this.taskService.customQuery(`task/search/dslquery`, {
            size: 1000,
            // sort: [{path: 'erpnumber', order: 'ASC'}],
            sort: [{ path: this.sortByValue, order: 'DESC' }],
            params: [{ key: 'search', value: query.join() }, { key: 'projection', value: 'details' }]
        }).subscribe((tasks: Task[]) => {
            this.entityCount = tasks.length;

            // if no data available, return empty entities
            if (!this.entityCount) {
                this.notificationService.isLoading.next(false);
                // this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('no data available'), null, 'info', 2000, false));
                return this.entities;
            }

            // get distinct order id´s from all tasks
            const distinctOrderIDs = [...Array.from(new Set(tasks.map((t: Task) => t.order_id)))];
            const taskOrders = [];

            // get order data by distinct order id´s
            this.entityService.customQueryPost('order/search/custom/list', {}, distinctOrderIDs).subscribe(
                (orders: Order[]) => orders.map((orderTask: Order) => taskOrders[orderTask.id] = orderTask),
                (error) => console.log(error),
                () => {
                    // merge tasks and orders by order id
                    // console.log('TASKS:', tasks);
                    tasks.map((task: Task) => {

                        // fix if status is missing
                        if (!task.status) {
                            const groupWork: WorkStep = task.completedWorksteps.filter((workstep: WorkStep) => workstep.name === 'Gruppenarbeit')[0];
                            if (groupWork) {
                                const selectionOrder = groupWork.selectionorder;
                                groupWork.success = task.completedWorksteps.filter((workstep: WorkStep) => workstep.parent === selectionOrder).every((ws: WorkStep) => ws.success);
                            }
                            task.status = task.completedWorksteps.every((workstep: WorkStep) => workstep.success) ? 'done' : task.status;
                        }

                        const index = +task.moment.format('d') - 1;
                        if (index >= 0) {
                            const priority = +(1 + this.entities[+task.moment.format('d') - 1].tasks.length) * this.priorityStep;
                            task.priority = task.priority || priority;
                            task.order = taskOrders[task.order_id];
                            if (this.filterTask(task)) {
                                this.entities[+task.moment.format('d') - 1].tasks.push(task);
                            }
                        }
                    });
                    // console.log('Filtered:', this.entities);
                    // sort all entities by priority
                    this.sortEntitiesBy('priority');
                    this.notificationService.isLoading.next(false);
                }
            );
        });
    }

    // sort tasks and errors
    private sortEntitiesBy(sortBy: string = 'priority') {
        this.entities.map((entity: { date: string, tasks: Task[] }) => entity.tasks.sort((a, b) => (a[sortBy] > b[sortBy]) ? 1 : -1));
    }

    // handle drag and drop events
    public drop(event: CdkDragDrop<Task[]>) {

        if (this.mode === 'quality') {
            this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('no drag&drop with error reports!'), null, 'info', 2000, false));
            return;
        }
        // move item up/down or right/left
        event.previousContainer === event.container ? moveItemInArray(event.container.data, event.previousIndex, event.currentIndex) : transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

        const container = event.container.data as Task[];
        const currentIndex = event.currentIndex;

        // get task item and and update data
        const item: Task = container[currentIndex];
        let updateItem = false;

        // set new Date
        if (item.date.substr(0, 10) !== event.container.id.substr(0, 10)) {
            item.date = event.container.id;
            updateItem = true;
        }

        // calculate current index (priority)
        if (event.previousIndex !== event.currentIndex || event.previousContainer !== event.container) {
            const prevPriority = +(container[currentIndex - 1] ? container[currentIndex - 1].priority : 0);
            const nextPriority = +(container[currentIndex + 1] ? container[currentIndex + 1].priority : prevPriority + this.priorityStep);
            item.priority = +(((nextPriority - prevPriority) / 2) + prevPriority).toFixed(0);
            updateItem = true;
        }

        // update item
        if (updateItem) {
            console.log('DROP:', item.erpnumber, item.id);
            this.taskService.changeDateTask(item.id, item).subscribe(
                () => this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.successfully.updated', { value: 'Auftrag' }), null, 'success', 2000, false)),
                (error: HttpErrorResponse) => this.notificationService.addNotification(new Notification(error.error.message || error.message, null, 'error', 5000)),
            );
        }
    }

    // open popups
    private onOpenDialog(dialog: string) {
        switch (dialog) {
            case 'selectItems':
                this.dialog.open(this.selectItemsDialog);
                break;
            case 'filter':
                this.dialog.open(this.filterDialog, { minWidth: '50vw' });
                break;
        }

    }

    // store selected items
    private onItemChange(event: string[]) {
        const itemName = this.mode === 'quality' ? 'errorOptions' : 'taskOptions';
        localStorage.setItem(itemName, event.filter((item: string) => item !== undefined).join(','));
    }

    // navigate to correct date
    private onSelectDate(event: MatDatepickerInputEvent<Date>) {
        this.datePicker.close();
        setTimeout(() => {
            this.selectedDate = moment(event.value).weekday(0);
            this.router.navigate(['/grid', this.mode, this.selectedDate.year(), this.selectedDate.week()], { relativeTo: this.route });
        }, 100);
    }

    // react to setting changes
    private onChangeSetting(setting: string, value: any) {
        this[setting] = value;
        if (setting === 'filterLastWorkstep' || setting === 'filterSteps' || setting === 'filterCompletedSteps') {
            this.onRefresh();
        }
        localStorage.setItem(setting, value.toString());
    }

    // react to date changes
    private onChangeDate(type: string = 'next' || 'prev' || 'reset') {
        switch (type) {
            case 'next':
                this.selectedDate.weekday(0).add(1, 'week');
                break;
            case 'prev':
                this.selectedDate.weekday(0).subtract(1, 'week');
                break;
            case 'reset':
                this.selectedDate = moment().weekday(0);
                break;
        }
        this.router.navigate(['/grid', this.mode, this.selectedDate.isoWeekYear(), this.selectedDate.isoWeek()], { relativeTo: this.route });
    }

    // toggle full screen mode
    private onToggleFullScreen() {
        this.fullscreen = !this.fullscreen;
        this.app.onToggleMode(this.fullscreen ? 'over' : 'side');
    }

    // reload data
    private onRefresh() {
        this.initFilterParams().subscribe(() => this.initGrid());
    }

    // toggles status to filter tiles
    private filterByStatus(status: string) {
        this.filter.includes(status) ? this.filter = this.filter.filter(item => item !== status) : this.filter.push(status);
    }

    // adds a search value
    private add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // add search value
        if ((value || '').trim()) {
            this.filter.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    // removes a search value
    private remove(value: string): void {
        const index = this.filter.indexOf(value);
        if (index >= 0) {
            this.filter.splice(index, 1);
        }
    }

    // redirect to correct component or open an popup
    public routerLink(e: any, task: Task) {

        if (this.userService.isAdmin() && e.altKey && !isNil(task)) {
            this.gridDoubleClick(e, task);
            return;
        }

        if (task.taskLock && !task.lockedByUser) {
            if (R.length(task.workgroup) > 0 && task.status === 'in-progress') {

            } else {
                return;
            }
        }

        switch (this.mode) {
            case 'reserved':
                this.dialog.open(this.reservedDialog, { data: task });
                break;
            case 'shop':
                if (R.length(task.workgroup) > 0 && task.status === 'in-progress') {
                    this.router.navigate(['/detail', 'in-progress', task.routes.uuid, 'workgroupmode'], { relativeTo: this.route });
                } else {
                    const route = R.includes(task.status, ['', null, undefined]) ? this.mode : null;
                    if (!isNil(route)) {
                        this.router.navigate(['/detail', route, task.routes.uuid], { relativeTo: this.route });
                    }
                }
                break;
            default:
                this.router.navigate(['/detail', this.mode, task.routes.uuid], { relativeTo: this.route });
        }

        // if (this.mode === 'reserved') {
        //     this.dialog.open(this.reservedDialog, {data: task}).afterClosed().subscribe((result: 'close' | 'reserve' | 'execute') => {
        //         console.log(result);
        //     });
        // } else if (this.mode === 'shop' && (R.includes(task.status, ['', null, undefined]))) {
        //     this.router.navigate(['/detail', this.mode, task.routes.uuid], {relativeTo: this.route});
        // } else if (this.mode === 'shop' && R.length(task.workgroup) > 0 && task.status === 'in-progress') {
        //     this.router.navigate(['/detail', 'in-progress', task.routes.uuid]);
        // } else if (this.mode === 'shop') {
        //     return;
        // } else {
        //     this.router.navigate(['/detail', this.mode, task.routes.uuid], {relativeTo: this.route});
        // }
    }

    // filter some tasks
    private filterTask(task: Task): boolean {
        const isLastWorkStep = item => isNil(item.step) ? true : +item.step !== +this.workStepCount;

        if (this.filterValues.freeUser && this.filterLastWorkstep) {
            return isLastWorkStep(task);
        } else if (this.filterValues.freeUser) {
            return true;
        }

        const isVisible = (item: Task): boolean => {
            if (item.userId === this.filterValues.userId) {
                return true;
            }
            if (R.isEmpty(item.departmentIds) || isNil(item.departmentIds)) {
                return false;
            }
            if (isNil(R.find((id) => id === this.filterValues.userDepartment)(item.departmentIds))) {
                return false;
            } else if (R.length(item.workgroup) > 0) {
                return true;
            } else if (this.filterSteps) {
                if (item.step == null || +item.step === 0) {
                    return true;
                } else if (!isNil(R.find((step: WorkStep) => +step.selectionorder === +item.step, this.filterValues.errorSteps))) {
                    // Checks if the step the task is in, is a errorstep or not.
                    return true; // If it is a errorstep, it shall be displayed.
                } else {
                    // if task has department gap!
                    let testStep = +item.step;
                    if (!R.includes(+item.step + 1, item.departmentSteps) && +item.step < R.last(item.departmentSteps)) {
                        const lastIndex = item.departmentSteps.findIndex((i => i === +item.step));
                        testStep = item.departmentSteps[lastIndex + 1] - 1;
                    }
                    return !isNil(R.find((step) => +step === +testStep)(this.filterValues.userSteps));
                }
            } else if (this.filterCompletedSteps) {
                return R.reduce(R.or, false,
                    R.map(st =>
                        R.includes(st, R.pluck('selectionorder', item.completedWorksteps.filter(step => !step.success)))
                    )(this.userService.getValidSteps())
                );
            } else {
                return true;
            }
        };

        return this.filterLastWorkstep ? isLastWorkStep(task) && isVisible(task) : isVisible(task);
    }

    // get task distribution
    public getDistribution(tasks: Task[]) {
        const distribution = [];
        tasks.map((task: Task) => {
            const status = task.status === '' ? 'null' : task.status;
            distribution[status] = (distribution[status]) ? distribution[status] + 1 : 1;
        });
        return distribution;
    }

    // reducer function
    public reduce = (items, prop) => items.reduce((a, b) => a + b[prop], 0);

    // calculates value in %
    public inPercent = (value: number, total: number, decimal: number = 1) => ((value * 100) / total).toFixed(decimal);

    // either is today or not
    public isToday = (date): boolean => moment(date).isSame(moment(), 'day');

    // custom compare function
    public customComparator = (a, b) => (a === null ? '' : a) > (b === null ? '' : b) ? 1 : -1;

    // either is visible or not
    public isVisible = (arr: string[], resource?: Entity | Task | Order): string => arr.find((name: string) => resource ? !!resource[name] && resource[name].length && this.selectedOptions.includes(name) : this.selectedOptions.includes(name));

    public isVisibleOption = (name: string): boolean => this.selectedOptions.includes(name);

    // either a string is empty or not
    public stringIsNotEmpty = (value: string): boolean => !(!value || 0 === value.length);

    // get name of the workstep
    public workstepName = (step: number) => nameForWorkstep(step, this.workstep);

    public gridDoubleClick = (e: any, task: Task) => {
        if (this.userService.isAdmin() && e.altKey && !isNil(task)) {
            this.taskService.unlocktask(task.id).subscribe(value => {
                this.notificationService.addNotification(new Notification('Admin UNLOCK send. '));
                this.onRefresh();
            });
        }
    }
}
