import {Deserializable} from '../deserializable.model';
import {Entity} from '../entity.model';
import {ResourceEntity} from './instruction.model';

export class ErrorReport extends Entity implements Deserializable {
    public client: string;
    public content: string;
    public deleted: boolean;
    public erpnumber: string;
    public errorStep: string;
    public id: string;
    public picture_2: any;
    public picture_3: any;
    public picture_resolved: any;
    public resourcesEntity: ResourceEntity;
    public scrap: boolean;
    public selectionorder: number;
    public version: number;
    public resolvedMessage: string;
    public showAsWarning: boolean;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    getClean() {
        const obj = {};
        Object.assign(obj, this);
        // @ts-ignore
        delete obj.resourcesEntity;
        return obj;
    }
}
