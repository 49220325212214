import * as R from 'ramda';
import {WorkStep} from '../models/entities/workstep.model';

export function getNamesForDepartmentIds(config: any) {
    // @ts-ignore
    const filterDepartments = department => R.includes(department.id, config.departments);
    return R.pluck('name', R.filter(filterDepartments, this.availableDepartments)).join(', ');
}

export function isNil(value: any) {
    return (value === undefined || value === null);
}

export function nameForWorkstep(step: number, workstepList: WorkStep[]) {
    const workstep = workstepList.filter(ws => ws.selectionorder === step)[0];
    return workstep.label + '. ' + workstep.name;
}

export function getLenght(o: any) {
    try {
        return R.length(o);
    } catch (e) {
        return -1;
    }
}

export function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
}
