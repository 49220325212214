import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './services/user.service';
import * as moment from 'moment';
import {CacheHelper} from 'angular4-hal';
import {NotificationService} from './services/notification.service';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {environment} from '../environments/environment';
import {ConfigService} from './services/config.service';
// @ts-ignore
import german from './../assets/i18n/de.json';
// @ts-ignore
import english from './../assets/i18n/en.json';
// @ts-ignore
import sosu from './../assets/i18n/sosu.json';
// @ts-ignore
import wim from './../assets/i18n/wim.json';
// @ts-ignore
import soh from './../assets/i18n/soh.json';
// @ts-ignore
import demo1 from './../assets/i18n/demo1.json';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    private isAuthenticated: boolean = this.userService.isAuthenticated;
    private collapsed: boolean;
    private isLoading = true;
    private opened = false;
    private mode: 'over' | 'push' | 'side' = 'side';

    constructor(private translate: TranslateService, private userService: UserService, private notificationService: NotificationService, private breakpointObserver: BreakpointObserver, private configService: ConfigService) {
        breakpointObserver.observe('(min-width: 600px)').subscribe((result: BreakpointState) => this.mode = result.matches ? 'side' : 'over');

        this.userService.authenticationChanged.subscribe((isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated);

        this.collapsed = localStorage.getItem('collapsed') === 'true' || false;

        this.notificationService.setNotifications(JSON.parse(localStorage.getItem('notifications')) || []);

        const lang = localStorage.getItem('lang') || environment.clientlang;
        translate.setTranslation('de', german);
        translate.setTranslation('demo1', demo1);
        translate.setTranslation('en', english);
        translate.setTranslation('sosu', sosu);
        translate.setTranslation('soh', soh);
        translate.setTranslation('wim', wim);
        translate.setDefaultLang('de');
        translate.use(environment.clientcode);

        moment.locale(lang);

        // disable global hal cache
        CacheHelper.isActive = false;

        this.notificationService.isLoading.subscribe((isLoading: boolean) => this.isLoading = isLoading);
        this.configService.init();
    }

    public onToggleMode(mode) {
        this.mode = mode;
    }

    public onToggleSideBar() {
        this.collapsed = !this.collapsed;
        localStorage.setItem('collapsed', this.collapsed.toString());
    }
}
