import { Inject, Injector } from '@angular/core';
import { ExternalConfigurationHandlerInterface, HalParam, Resource, RestService } from 'angular4-hal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AutoCompleteOptions, Entity } from '../models/entity.model';
import { Profile, Property } from '../models/profile.model';
import { Result } from '../models/result.model';

/**
 * DSL Query SYNTAX.
 * /search/dslext?and={andQuery}&or={orQuery}
 * "::|:<|:>|<<|>>|!:" + // Numeric Comperatoren // ==, <=, >=, <, >, !=
 * :%|!%|%%|" + // String Compaeratoren // String contains , string not like, string like
 * :§|!§|" + // UUID Comperatoren // UUID == UUID, UUID != UUID
 * :b|!b|" + // Binary (true/false) Comperatoren && true == true , true != true
 * bd|<d|>d" + // Date Comperatoren // compare (DateFrom;DateTo), date <, date >
 * orQuery.and(andQuery) "andQuery" and "orQuery" can be empty.
 */

// @ts-ignore
export class HalService<T extends Entity> extends RestService<T> {

    public rootUri: string;
    public dateFormatFull = 'YYYY-MM-DD HH:mm:ss';
    public dateFormat = 'dd.MM.yyyy';
    private httpHalOptions = { headers: new HttpHeaders().set('Accept', 'application/hal+json') };
    private httpSchemaOptions = { headers: new HttpHeaders().set('Accept', 'application/schema+json') };

    constructor(type: new () => T, injector: Injector, public httpClient: HttpClient, @Inject('ExternalConfigurationService') private externalConfigurationService: ExternalConfigurationHandlerInterface) {
        super(type, '', injector);
        this.rootUri = this.externalConfigurationService.getRootUri();
    }

    public customQueryGet(path: string, params: HalParam[] = []): Observable<any> {
        const httpParams = {};
        params.map((param: HalParam) => (httpParams[param.key] = param.value.toString()));
        return this.httpClient.get(this.rootUri + path, { params: httpParams as HttpParams });
    }

    public customPost(path: string, body: {}): Observable<any> {
        return this.httpClient.post<any>(this.rootUri + path, body);
    }

    public customDelete(path: string): Observable<any> {
        return this.httpClient.delete<any>(this.rootUri + path);
    }

    public customPut(path: string, body: {}): Observable<any> {
        return this.httpClient.put<any>(this.rootUri + path, body);
    }

    /**
     * Executes a DSL Query
     */
    public executeDslQuery(value: string, options: AutoCompleteOptions): Observable<any[]> {
        const params = new HttpParams().set('search', `${options.key}:${value.toString()}`).set('projection', 'detail').set('size', options.size.toString());
        return this.httpClient.get(this.rootUri + options.path + '/search/dslquery', { params }).pipe(map((result: Result) => result._embedded ? result._embedded[options.path] as Entity[] : []));
    }

    /**
     * returns form fields of a resource
     */
    public getFieldProperties(path: string): Observable<{ [name: string]: Property }> {
        return this.httpClient.get(`${this.rootUri}profile/${path}`, this.httpSchemaOptions).pipe(
            map((profile: Profile) => {
                Object.keys(profile.properties).map((name: string) => {
                    profile.properties[name].type = this.getFieldType(name, profile.properties[name]);
                    profile.properties[name].key = name;
                });
                return profile.properties;
            })
        );
    }

    /**
     * get root links of api
     */
    public getRootLinks(): Observable<any[]> {
        return this.httpClient.get(this.rootUri, this.httpHalOptions).pipe(
            map((resource: Resource) => Object.keys(resource._links).filter(link => resource._links[link].templated).sort()
            )
        );
    }

    private getFieldType(name: string, property: Property) {
        switch (name) {
            case 'date':
            case 'deliveryDate':
                return 'date';

            case 'status':
                return 'status';

            case 'grantedauthority':
                return 'role';

            case 'workplace':
            case 'place':
            case 'step':
                return 'select';

            case 'resource':
                return 'csv';

            case 'email':
                return 'email';

            case 'client':
            case 'erpnumber':
            case 'erpnumberSmall':
            case 'worker':
            case 'companyName':
            case 'owningTask':
            case 'clientNumber':
                return 'autocomplete';

            case 'resourcesEntityPdf':
                return 'pdf';

            case 'resourcesEntity':
            case 'picture_2':
            case 'picture_3':
            case 'picture_resolved':
                return 'image';

            case 'workstepEntityList':
            case 'userEntityList':
                return 'ManyToManyRelation';

            case 'avatar':
                return 'icon';

            case 'departmentEntity':
                return 'ManyToOneRelation';

            case 'content':
            case 'description':
                return 'textarea';

            default:
                return property.type;
        }
    }
}
