import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from 'src/app/material.module';
import {AngularHalModule} from 'angular4-hal';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {ExternalConfigurationService} from './ExternalConfigurationService';
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';
import {ListComponent} from './components/list/list.component';
import {EditComponent} from './components/edit/edit.component';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {AuthInterceptor} from 'src/app/interceptors/auth.interceptor';
import {LoginComponent} from './components/login/login.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ChartsModule} from 'ng2-charts';
import {UserService} from './services/user.service';
import {EncodeHttpParamsInterceptor} from './interceptors/encodeHttpParams.interceptor';
import {ShortenPipe} from './pipes/shorten.pipe';
import {RichTextEditorAllModule} from '@syncfusion/ej2-angular-richtexteditor';
import {NotificationComponent} from './components/notification/notification.component';
import {ReplacePipe} from './pipes/replace.pipe';
import {ImprintComponent} from './components/imprint/imprint.component';
import {OrderModule} from 'ngx-order-pipe';
import {FilesizePipe} from './pipes/filesize.pipe';
import {CreateFilePipe} from './pipes/create-file.pipe';
import {ErrorComponent} from './components/error/error.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {GridComponent} from './components/grid/grid.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CalendarComponent} from './components/calendar/calendar.component';
import {DefaultvalPipe} from './pipes/default.pipe';
import {MatGridListModule} from '@angular/material/grid-list';
import {FilterPipe} from './pipes/filter.pipe';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {DetailComponent} from './components/detail/detail.component';
import {DisponentComponent} from './components/detail/disponent/disponent.component';
import {QualityComponent} from './components/detail/quality/quality.component';
import {InProgressComponent} from './components/detail/in-progress/in-progress.component';
import {WorkerComponent} from './components/detail/worker/worker.component';
import {DateDiffPipe} from './pipes/date-diff.pipe';
import {CustomFormsModule} from 'ng2-validation';
import {DateAdapter} from '@angular/material/core';
import {CustomDateAdapter} from './helpers/custom-date-adapter';
import { PictureComponent } from './components/picture/picture.component';
import { FindComponent } from './components/find/find.component';
import { HighlightPipe } from './pipes/highlight.pipe';

export function getAuthState(userService: UserService) {
    return () => userService.checkAuthenticationStatus();
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent, ListComponent, EditComponent, ToolbarComponent, SidenavComponent, LoginComponent, DashboardComponent, ShortenPipe, NotificationComponent, ReplacePipe, ImprintComponent, FilesizePipe, CreateFilePipe, ErrorComponent, SafeHtmlPipe, GridComponent, CalendarComponent, DefaultvalPipe, FilterPipe, DetailComponent, DisponentComponent, QualityComponent, InProgressComponent, WorkerComponent, DateDiffPipe, PictureComponent, FindComponent, HighlightPipe],
    imports: [
        ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        HttpClientModule,
        FormsModule,
        CustomFormsModule,
        MaterialModule,
        ChartsModule,
        OrderModule,
        RichTextEditorAllModule,
        AngularHalModule.forRoot(),
        TranslateModule.forRoot({loader: {provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient]}}),
        DragDropModule,
        MatGridListModule,
        PdfViewerModule,
    ],
    providers: [
        {provide: DateAdapter, useClass: CustomDateAdapter},
        {provide: APP_INITIALIZER, useFactory: getAuthState, deps: [UserService], multi: true},
        {provide: LOCALE_ID, useValue: 'de-DE'}, // 'de-DE' for Germany, 'fr-FR' for France ...
        {provide: 'ExternalConfigurationService', useClass: ExternalConfigurationService},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: EncodeHttpParamsInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        registerLocaleData(localeDe);
    }
}
