import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Notification, NotificationService} from '../../services/notification.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ThemePalette} from '@angular/material/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {TooltipPosition} from '@angular/material/tooltip';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
    @ViewChild('notificationDialog', {static: true}) notificationDialog: TemplateRef<any>;
    private tooltipPosition: TooltipPosition = 'right';
    private notifications: Notification[] = this.notificationService.notifications;
    private notificationOptions: { [name: string]: { icon: string, color: ThemePalette } } = {
        error: {icon: 'warning', color: 'warn'},
        success: {icon: 'thumb_up', color: 'accent'},
        info: {icon: 'notification_important', color: 'primary'}
    };

    constructor(private notificationService: NotificationService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog) {
        this.notificationService.notificationsChanged.subscribe((notifications: Notification[]) => this.notifications = notifications);
    }

    ngOnInit() {
    }

    /**
     * Show notification
     */
    private onShowNotification(idx: number) {
        this.dialog.open(this.notificationDialog, {data: {notification: this.notifications[idx], index: idx}});
    }

    private onNavigateTo(link: string) {
        this.router.navigate([link]);
    }

    private getDateDiff(date: Moment): string | boolean {
        const duration = moment.duration(moment(date).diff(moment()));
        return date ? duration.humanize(true) : false;
    }
}
