import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource, SortDirection} from '@angular/material';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {merge, of as observableOf, Subscription} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {SortOrder} from 'angular4-hal/src/sort';
import {TranslateService} from '@ngx-translate/core';
import {SelectionModel} from '@angular/cdk/collections';
import {environment} from 'src/environments/environment';
import {UserService} from '../../services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {Notification, NotificationService} from '../../services/notification.service';
import {AngularCsv} from 'angular-csv-ext/dist/Angular-csv';
import {Entity} from '../../models/entity.model';
import {Property} from '../../models/profile.model';
import {EntityService} from '../../services/entity.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
    @ViewChild('deleteDialog', {static: true}) deleteDialog: TemplateRef<any>;
    @ViewChild('addDialog', {static: true}) addDialog: TemplateRef<any>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    defaultPageSizeOptions = [10, 30, 50, 100];
    defaultPageSize: number;
    resultsLength = 0;
    protected displayedColumns: string[] = [];
    protected allColumns: string[] = [];
    protected dataSource: MatTableDataSource<Entity>;
    protected selection = new SelectionModel<Entity>(true, []);
    private filter: string[] = [];
    private dataSubscription: Subscription;
    private routeSubscription: Subscription;
    private path: string;
    private properties: { [name: string]: Property };

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private entityService: EntityService, private translate: TranslateService, private userService: UserService, private dialog: MatDialog, private notificationService: NotificationService) {
        this.filter = environment.filter.list.excludes;
    }

    /** listener for window resize event */
    @HostListener('window:resize', ['$event']) onResize() {
        this.getDisplayedColumns();
    }

    ngOnInit() {
        this.routeSubscription = this.route.params.subscribe((params: Params) => {
            if (this.dataSubscription) {
                this.dataSubscription.unsubscribe();
            }

            this.notificationService.isLoading.next(true);

            this.defaultPageSize = +localStorage.getItem('pageSize') || this.defaultPageSizeOptions[0];
            this.path = params.path;

            // get field properties and load table
            this.entityService.getFieldProperties(this.path).subscribe((properties: { [name: string]: Property }) => {
                this.properties = properties;
                this.initTable();
            });
        });
    }

    ngOnDestroy(): void {
        this.notificationService.isLoading.next(false);
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    private isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    private masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** navigate to a route */
    private onNavigateTo(path) {
        this.router.navigate([path]);
    }

    /** adds an entity */
    private onAddEntity() {
        this.dialog.open(this.addDialog);
    }

    /** opens delete popup */
    private onDeleteEntity(entity: Entity) {
        this.dialog.open(this.deleteDialog, {data: {name: entity.name || entity.fullname || entity.client || this.path}}).afterClosed().subscribe(
            (result: boolean) => {
                if (result === true) {
                    this.deleteEntity(entity);
                }
            });
    }

    /** removes an entity */
    private deleteEntity(entity: Entity) {
        this.entityService.delete(entity).subscribe(
            () => '',
            (error: HttpErrorResponse) => this.notificationService.addNotification(new Notification(error.error.message || error.message, this.path, 'error', 5000)),
            () => {
                this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.successfully.deleted', {value: this.notificationService.translate.instant(this.path) })));
                this.initTable();
            }
        );
    }

    /** filters list by search values */
    private applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    /** handles displayed columns by screen width */
    private getDisplayedColumns() {
        const collapsed = localStorage.getItem('collapsed') === 'true' || false;
        const width = window.innerWidth;
        const columns = +(width / 100).toFixed() - (this.userService.isAdmin() ? 5 : 3) + (+collapsed);
        this.displayedColumns = this.allColumns.slice(0, columns <= 1 ? 2 : columns > 10 ? 10 : columns);

        // add select and delete column
        if (this.userService.isAdmin()) {
            this.displayedColumns.unshift('select');
            this.displayedColumns.push('delete');
        }
    }

    /** inits table date */
    private initTable() {
        this.paginator.firstPage();
        this.paginator.pageSize = this.defaultPageSize;
        this.sort.active = '';
        this.sort.direction = 'asc' as SortDirection;
        this.displayedColumns = [];
        this.dataSource = new MatTableDataSource([]);

        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.firstPage());


        this.dataSubscription = merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    localStorage.setItem('pageSize', this.paginator.pageSize.toString());
                    this.selection.clear();
                    return this.entityService.customQuery(this.path, {
                        size: this.paginator.pageSize,
                        sort: this.sort.active && this.sort.direction && [{path: this.sort.active, order: this.sort.direction as SortOrder}],
                        params: [{key: 'page', value: this.paginator.pageIndex}, {key: 'projection', value: 'detail'}]
                    });
                }),
                map((data: Entity[]) => {
                    this.resultsLength = this.entityService.totalElement();
                    this.allColumns = data.length ? Object.keys(data[0]).filter((key: string) => !this.filter.includes(key)).sort() : Object.keys(this.properties);
                    this.getDisplayedColumns();
                    this.notificationService.isLoading.next(false);
                    return data;
                }),
                catchError(() => {
                    return observableOf([]);
                })
            )
            .subscribe((data: Entity[]) => this.dataSource = new MatTableDataSource(data));
    }

    /** Exports selected rows to csv file */
    private onCsvExport() {
        const selectedData = this.selection.selected;
        if (selectedData.length) {
            const data: any[] = [];
            const header = Object.keys(selectedData[0]).filter((key: string) => !this.filter.includes(key));
            selectedData.map((entity: Entity) => data.push(header.map((head: string) => entity[head] || '')));
            const options = {fieldSeparator: ',', quoteStrings: '"', decimalseparator: 'locale', headers: header};
            return new AngularCsv(data, this.translate.instant(this.path), options);
        }
    }
}
