import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateDiff'
})
export class DateDiffPipe implements PipeTransform {

    transform(date: any): string {
        return moment.duration(moment(date).diff(moment())).humanize(true);
    }

}
