import {Deserializable} from '../deserializable.model';
import {Entity} from '../entity.model';

export class WorkStep extends Entity implements Deserializable {
    public label: string;
    public name: string;
    public parent: number;
    public selectionorder: number;
    public workstepType: string;
    public completedParts: number;
    public workerName: string;
    public success: boolean;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
