import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'shorten'})
@Injectable({providedIn: 'root'})
export class ShortenPipe implements PipeTransform {
    transform(value: string, limit: number, min: number = 5) {
        limit = limit < min ? min : limit;
        return (value && value.length > limit) ? value.substr(0, limit) + ' ...' : value;
    }
}
