import {Injectable, Pipe, PipeTransform} from '@angular/core';
import * as R from 'ramda';

@Pipe({name: 'defaultval'})
@Injectable({providedIn: 'root'})
export class DefaultvalPipe implements PipeTransform {
    transform(value: any, defaultvalue: any) {
        if (value === undefined || value === null || R.isEmpty(value)) {
            return defaultvalue;
        } else {
            return value;
        }
    }
}
