import {Deserializable} from '../deserializable.model';
import {Entity} from '../entity.model';

export class ConfigList extends Entity implements Deserializable {
    public displaycurrentstep: string;
    public displaydonetab: string;
    public displayscrapbutton: string;
    public displayshop: string;
    public disponentnext: string;
    public extendtileinformation: string;
    public fertigungsleiteremail: string;
    public filterbysteps: string;
    public filterlastworkstep: string;
    public labelconfig: string;
    public noworkplace: string;
    public qualityemail: string;
    public randomworksteps: string;
    public timea: string;
    public timeb: string;
    public timec: string;
    public prioritystep: string;
    public defaulthelp: string;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
