import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListComponent } from 'src/app/components/list/list.component';
import { EditComponent } from 'src/app/components/edit/edit.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './services/auth-guard.service';
import { NotificationComponent } from './components/notification/notification.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { ErrorComponent } from './components/error/error.component';
import { GridComponent } from './components/grid/grid.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { DisponentComponent } from './components/detail/disponent/disponent.component';
import { InProgressComponent } from './components/detail/in-progress/in-progress.component';
import { QualityComponent } from './components/detail/quality/quality.component';
import { WorkerComponent } from './components/detail/worker/worker.component';
import { FindComponent } from './components/find/find.component';

const routes: Routes = [

    { path: 'error', component: ErrorComponent },
    { path: 'login', component: LoginComponent },

    // static components
    { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'notifications', component: NotificationComponent, canActivate: [AuthGuard] },
    { path: 'imprint', component: ImprintComponent, canActivate: [AuthGuard] },
    { path: 'find', component: FindComponent, canActivate: [AuthGuard] },

    // task views
    { path: 'grid/:mode', component: GridComponent, canActivate: [AuthGuard], data: { accessControl: true } },
    { path: 'grid/:mode/:year/:week', component: GridComponent, canActivate: [AuthGuard], data: { accessControl: true } },
    { path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard], data: { accessControl: true } },

    // detail views
    { path: 'detail/dispo/:uuid', component: DisponentComponent, canActivate: [AuthGuard], data: { accessControl: true } },
    { path: 'detail/in-progress/:uuid', component: InProgressComponent, canActivate: [AuthGuard], data: { accessControl: true } },
    { path: 'detail/in-progress/:uuid/:workgroupmode', component: InProgressComponent, canActivate: [AuthGuard], data: { accessControl: true } },
    { path: 'detail/quality/:uuid', component: QualityComponent, canActivate: [AuthGuard], data: { accessControl: true } },
    { path: 'detail/shop/:uuid', component: WorkerComponent, canActivate: [AuthGuard], data: { accessControl: true } },

    // dynamic components
    { path: ':path', component: ListComponent, canActivate: [AuthGuard], data: { accessControl: true } },
    { path: ':path/:identifier', component: EditComponent, canActivate: [AuthGuard], data: { accessControl: true } },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule {
}
