import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';
import {Moment} from 'moment';
import {TranslateService} from '@ngx-translate/core';

export type NotificationType = 'success' | 'error' | 'info';

export class Notification {
    constructor(
        public message: string,
        public link: string = null,
        public type: NotificationType = 'info',
        public duration: number = 2000,
        public saveNotification: boolean = true,
        public action: string = 'OK',
        public timeStamp: Moment = moment()
    ) {

    }
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    public isLoading = new BehaviorSubject<boolean>(false);
    public isBuffering = new BehaviorSubject<boolean>(false);

    public notificationsChanged = new Subject<Notification[]>();
    public notifications: Notification[] = [];

    constructor(private snackBar: MatSnackBar, public translate: TranslateService) {
    }

    public clearNotifications() {
        this.notifications = [];
        this.updateNotifications();
    }

    public setNotifications(notifications: Notification[]) {
        this.notifications = notifications;
        this.notificationsChanged.next(this.notifications.slice());
    }

    public deleteNotification(index: number) {
        this.notifications.splice(index, 1);
        this.updateNotifications();
    }

    public addNotification(notification: Notification) {
        this.notify(notification);
        if (notification.saveNotification) {
            this.notifications.unshift(notification);
            this.updateNotifications();
        }
    }

    private notify(notification: Notification) {
        this.snackBar.open(notification.message, notification.action, {duration: notification.duration, panelClass: 'style-' + notification.type});
    }

    private updateNotifications() {
        this.notificationsChanged.next(this.notifications.slice());
        localStorage.setItem('notifications', JSON.stringify(this.notifications.slice()));
    }

}
