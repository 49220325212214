import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TaskService} from '../../../services/task.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {ConfigService} from '../../../services/config.service';
import {Location} from '@angular/common';
import {DetailComponent} from '../detail.component';
import {ErrorReport} from '../../../models/entities/error-report.model';
import {ErrorTrackingService} from '../../../services/error-tracking.service';
import {Task} from 'src/app/models/entities/task.model';
import {MatDialog} from '@angular/material';
import {WorkStep} from '../../../models/entities/workstep.model';
import {ImageFile} from '../../edit/edit.component';
import {Notification, NotificationService} from '../../../services/notification.service';
import {getLenght} from '../../../helpers/common';
import {AppComponent} from '../../../app.component';

@Component({
    selector: 'app-in-progress',
    templateUrl: './in-progress.component.html',
    styleUrls: ['./in-progress.component.scss', '../detail.component.scss']
})
export class InProgressComponent extends DetailComponent implements OnInit, OnDestroy {
    @ViewChild('imageDialog', {static: true}) imageDialog: TemplateRef<any>;
    @ViewChild('pdfDialog', {static: true}) pdfDialog: TemplateRef<any>;
    @ViewChild('helpDialog', {static: true}) helpDialog: TemplateRef<any>;
    @ViewChild('errorDialog', {static: true}) errorDialog: TemplateRef<any>;
    @ViewChild('stepDialog', {static: true}) stepDialog: TemplateRef<any>;
    @ViewChild('selectWorkgroupChildren', {static: true}) selectWorkgroupChildren: TemplateRef<any>;
    public defaultHelpMessages: string[] = [];
    public helpMessage = '';
    public workstepChildren: WorkStep[];
    public warnings: ErrorReport[] = [];
    public warningsAccepted = false;
    public state = {
        quantity: 0,
        workplace: '',
        workstep: undefined,
        departments: undefined,
        saveddepartments: undefined,
        worker: undefined,
        customlabel: undefined,
        labels: undefined,
        deliveryDate: undefined,
        timea: undefined,
        timeb: undefined,
        timec: undefined,
        workgroup: undefined,
        selectedWorkstep: WorkStep,
        numberCompletedParts: undefined,
    };
    private photos: ImageFile[] = [];

    constructor(r: ActivatedRoute, ts: TaskService, sc: ConfigService, l: Location, n: NotificationService, private us: UserService, private errorTrackingService: ErrorTrackingService, private dialog: MatDialog, private router: Router, private a: AppComponent) {
        super(r, ts, sc, l, n, us, a);
    }

    ngOnInit() {
        this.init().subscribe((task: Task) => this.initState(task));
        this.configService.getClientConfig().subscribe(config => this.defaultHelpMessages = config.defaulthelp ? JSON.parse(config.defaulthelp) : []);
    }

    ngOnDestroy(): void {
        this.taskService.stopTracking();
    }

    private unlock(path: string){
        this.taskService.unlocktask(this.task.id).subscribe(v2 => {
            this.router.navigate(['/grid', path], {relativeTo: this.route});
        });
    }

    public onPauseTask() {
        this.taskService.pauseTask(this.task.id, this.task).subscribe(value => {
            this.router.navigate(['/grid', 'in-progress'], {relativeTo: this.route});
        });

    }

    public onFreeWorkgroup() {
        Object.assign(this.task, {workgroup: this.userService.getId()});
        this.taskService.reserveTask(this.task.id, this.task).subscribe(value => {
            this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.successfully.enabled', {value: this.notificationService.translate.instant('workgroup')})));
        });
    }

    public onCloseTask() {
        this.taskService.completeTaskStep(this.task.id, this.task, this.state.numberCompletedParts).subscribe(() => {
            this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.info.completed', {value: this.notificationService.translate.instant('step')})));
            this.unlock('in-progress');
        });
    }

    public onReportError(value: { message: string, quantity: number, workstep: WorkStep }) {
        Object.assign(this.task, {status: 'failed', quantity: value.quantity});
        this.taskService.reserveTask(this.task.id, this.task).subscribe(() => {
            this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.successfully.created', {value: this.notificationService.translate.instant('error.report')})));
            this.errorTrackingService.createErrorReport(this.task, value.quantity, value.message, false, value.workstep.selectionorder, this.photos)
                .subscribe(() => {
                    this.unlock('in-progress');
                });
        });
    }

    public onOpenImageDialog(index: number) {
        this.dialog.open(this.imageDialog, {data: index});
    }

    public onOpenHelpDialog() {
        this.dialog.open(this.helpDialog);
    }

    public onOpenStepDialog() {
        this.dialog.open(this.stepDialog);
    }

    public onOpenPdfDialog() {
        this.dialog.open(this.pdfDialog);
    }

    public onOpenErrorDialog() {
        this.dialog.open(this.errorDialog);
    }

    public onNavigateBack() {
        if (this.workgroupmode) {
            this.router.navigate(['/grid', 'shop'], {relativeTo: this.route});
        } else {
            this.unlock('in-progress');
        }
    }

    public onToggleHelpMessage(message: string) {
        this.helpMessage = (this.helpMessage.includes(message) ? this.helpMessage.replace(new RegExp(message, 'g'), '') : message + '\n' + this.helpMessage).trim();
    }

    public concat = (arr1: any[], arr2: any[]) => [...arr1, ...arr2];

    private updateCompletedParts(numberCompletedParts: number) {
        this.setState({numberCompletedParts});
    }

    private initState(task: Task) {
        this.setState(this.taskService.createTaskState(task, this.labels, this.worksteps, this.availableDepartments, this.availableUsers));
        this.setState({numberCompletedParts: 0});
        this.errorTrackingService.getWarnings(task).subscribe((warnings: ErrorReport[]) => {
            this.warningsAccepted = warnings.length === 0;
            this.warnings = warnings;
        });
        this.taskService.startTracking(task);
        this.taskService.findChildWorksteps(task.step + '').subscribe(value => {
            this.workstepChildren = value;
            if (getLenght(value) > 0) {
                this.dialog.open(this.selectWorkgroupChildren);
            }
        });
    }

    private onCallProductionManager() {
        this.taskService.callProductionManager(this.helpMessage, this.task).subscribe();
    }

    private onCallQaManager() {
        this.taskService.callQaManager(this.helpMessage, this.task).subscribe();
    }
}
