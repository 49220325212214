export const environment = {
    production: false,
    hmr: false,
    client: 'sontheimer',
    clientlang: 'de',
    clientcode: 'soh',
    name: 'Kapp Automation GmbH',
    apiurl: 'https://api.kap.teamsprint.de/api-v1/',
    required: {
        user: ['firstname', 'lastname', 'email', 'grantedauthority'],
        department: ['name'],
        instruction: ['client', 'erpnumber'],
        task: ['erpnumber'],
        csvupload: ['client', 'date', 'number'],
        handbook: ['client', 'erpnumber'],
        errorreport: ['client', 'erpnumber'],
    },
    filter: {
        sidenav: {
            excludes: [],
            includes: [
                /* dynamic routes */ 'user', 'department', 'instruction', 'errorreport', 'task', 'csvupload', 'handbook', 'clientConfig', 'calendar', 'grid', 'workplace',
                /* grid routes */ 'dispo', 'in-progress', 'quality', 'shop', 'reserved',
                /* detail routes */ 'detail/dispo', 'detail/in-progress', 'detail/quality', 'detail/shop'
            ]
        },
        list: {
            excludes: ['@id', '_links', 'id', 'departmentEntity', 'resource', 'fullname', '_id', 'order_id', 'userId', 'records', 'timea', 'timeb', 'timec', 'departmentIds', 'avatar', 'description', 'owningTask', 'deleted', 'content', 'qrCode', 'validSelectionorders', 'pictures'],
            includes: []
        },
        edit: {
            excludes: ['id', '_id', 'deleted', 'records', 'avatar', 'qrCode', 'order_id', 'owningTask', 'departmentIds', 'priority', 'pictures'],
            includes: []
        }
    },
    access: {
        user: ['ROLE_ADMIN'],
        workplace: ['ROLE_ADMIN'],
        department: ['ROLE_ADMIN'],
        instruction: ['ROLE_ADMIN', 'ROLE_DISPO'],
        errorreport: ['ROLE_ADMIN', 'ROLE_QA'],
        dashboard: ['ROLE_ADMIN', 'ROLE_DISPO', 'ROLE_OPMANAGER'],
        task: ['ROLE_ADMIN', 'ROLE_DISPO', 'ROLE_OPMANAGER'],
        csvupload: ['ROLE_ADMIN', 'ROLE_DISPO'],
        handbook: ['ROLE_ADMIN', 'ROLE_DISPO'],
        clientConfig: ['ROLE_ADMIN'],
        /* e.g.: */
        // 'detail/shop': ['ROLE_WORKER'],
        dispo: ['ROLE_DISPO', 'ROLE_ADMIN', 'ROLE_OPMANAGER'],
        'detail/dispo': ['ROLE_DISPO', 'ROLE_ADMIN', 'ROLE_OPMANAGER'],
        quality: ['ROLE_QA', 'ROLE_ADMIN'],
        'detail/quality': ['ROLE_QA', 'ROLE_ADMIN'],
        calendar: ['ROLE_DISPO', 'ROLE_ADMIN', 'ROLE_OPMANAGER', 'ROLE_QA'],
        // dispo: ['ROLE_WORKER'],
        // 'in-progress': ['ROLE_WORKER']
    },
    relation: {
        grantedauthority: ['ROLE_WORKER', 'ROLE_ADMIN', 'ROLE_QA', 'ROLE_DISPO', 'ROLE_OPMANAGER'],
        status: ['done', 'failed', 'in-progress', 'reserved', 'disponent'],
        workplace: [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => `A${i}`), // returns A1, A2, ... A5
        place: [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => `A${i}`), // returns A1, A2, ... A5
        workstepEntityList: {path: 'workstep', selector: 'name'},
        userEntityList: {path: 'user', selector: 'email'},
        resourcesEntity: {path: 'resourcesEntity', selector: 'name'},
        departmentEntity: {path: 'department', selector: 'name'},
        step: Array.from({length: 15}, (value, key) => key + 1), // returns [1,2,3, ... 15]
    }
};
