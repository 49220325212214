import {Component, OnDestroy} from '@angular/core';
import 'moment/locale/de';
import {ActivatedRoute, Params} from '@angular/router';
import {ConfigService} from '../../services/config.service';
import * as R from 'ramda';
import {HasState} from '../../helpers/hasState';
import {combineLatest, Observable, throwError} from 'rxjs';
import {Location} from '@angular/common';
import {Department} from '../../models/entities/department.model';
import {Instruction} from '../../models/entities/instruction.model';
import {TaskService} from '../../services/task.service';
import {Handbook} from '../../models/entities/handbook.model';
import {ConfigList} from '../../models/entities/config-list.model';
import {Task} from 'src/app/models/entities/task.model';
import {WorkStep} from '../../models/entities/workstep.model';
import {User} from '../../models/entities/user.model';
import {Notification, NotificationService} from '../../services/notification.service';
import {UserService} from '../../services/user.service';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {isNil} from '../../helpers/common';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent extends HasState implements OnDestroy {

    public task: Task;
    public workstepCount: number;
    public children: Task[];
    public displayValues = ['departmentEntityList', 'originalQuantity', 'quantity', 'workplace', 'workstep', 'worker', 'status', 'timea', 'timeb', 'timec', 'workgroup'];
    public workplaces: string[] = [];
    public worksteps: WorkStep[] = [];
    public errorWorksteps: WorkStep[] = [];
    public departmentConfigs: Department[] = [];
    public availableDepartments: Department[] = [];
    public availableUsers: User[] = [];
    public labels: string[] = [];
    public instructions: Instruction[] = [];
    public handbooks: Handbook[] = [];
    public noworkplace: boolean;
    public showTime: { time: string };
    public workgroupmode = false;
    public alreadyDoneWorksteps = [];

    tools: object = {
        items: [
            'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
            'FontSize', 'FontColor', 'BackgroundColor', '|',
            'Undo', 'Redo', '|',
            'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
            'Indent', 'Outdent', '|', 'SourceCode']
    };

    constructor(public route: ActivatedRoute, public taskService: TaskService, public configService: ConfigService, public location: Location, public notificationService: NotificationService, public userService: UserService, public app: AppComponent) {
        super();
        this.app.onToggleMode('over');
    }

    getRequestArray(task: Task) {
        const dispo = [
            this.taskService.getWorkStepCount(),
            this.taskService.getWorkplaces(),
            this.taskService.getAllWorkSteps(),
            this.taskService.getDepartments(),
            this.taskService.getUsers(),
            this.configService.getLabels(),
            this.taskService.getChildren(task.id),
            this.taskService.getDepartmentConfig(task.id),
            this.taskService.getInstructions(task),
            this.taskService.getHandbooks(task),
            this.configService.config,
            this.configService.showTime(task),
            this.taskService.getErrorWorkSteps(),
        ];

        const user = [
            this.taskService.getWorkStepCount(),
            this.taskService.getWorkplaces(),
            this.taskService.getAllWorkSteps(),
            this.taskService.getDepartments(),
            this.getEmptyObservable(),
            this.configService.getLabels(),
            this.taskService.getChildren(task.id),
            this.taskService.getDepartmentConfig(task.id),
            this.taskService.getInstructions(task),
            this.taskService.getHandbooks(task),
            this.configService.config,
            this.configService.showTime(task),
            this.taskService.getErrorWorkSteps(),
        ];

        if (this.userService.isDispoOrAdmin()) {
            return dispo;
        } else {
            return user;
        }
    }

    getEmptyObservable() {
        return new Observable(subscriber => subscriber.next([]));
    }

    // init data
    init() {
        this.notificationService.isLoading.next(true);
        return new Observable<Task>(subscriber => {
            this.route.params.subscribe((params: Params) => {

                if (isNil(params.workgroupmode)) {
                    this.taskService.getRequiredLockedTask(params.uuid)
                        .pipe(
                            catchError((error: HttpErrorResponse) => {
                                // if 0 Unknown Error returned from api -> user is not logged in anymore
                                this.notificationService.addNotification(new Notification(this.notificationService.translate.instant('messages.error.locked', {value: 'Auftrag'}), null, 'error'));
                                this.location.back();
                                return throwError(error);
                            })
                        )
                        .subscribe(
                            (task: Task) => {
                                this.task = task;
                                this.alreadyDoneWorksteps = R.pluck('selectionorder')(R.filter(step => step.success, task.completedWorksteps));

                                combineLatest(this.getRequestArray(task)
                                ).subscribe(resultList => {
                                    this.workstepCount = resultList[0] as number;
                                    this.workplaces = resultList[1] as string[];
                                    this.worksteps = resultList[2] as WorkStep[];
                                    this.availableDepartments = resultList[3] as Department[];
                                    this.availableUsers = resultList[4] as User[];
                                    this.labels = resultList[5] as string[];
                                    this.children = resultList[6] as Task[];
                                    this.departmentConfigs = this.getDepartmentConfig(resultList[7] as Department[]);
                                    this.instructions = resultList[8] as Instruction[];
                                    this.handbooks = resultList[9] as Handbook[];
                                    this.noworkplace = (resultList[10] as ConfigList).noworkplace === 'true';
                                    this.showTime = resultList[11] as { time: string };

                                    this.errorWorksteps = resultList[12] as WorkStep[];

                                    if (task.step != null && !isNil(this.errorWorksteps.find((step: WorkStep) => +step.selectionorder === +task.step))) {
                                        this.worksteps = this.worksteps.concat(this.errorWorksteps);
                                    }

                                    this.notificationService.isLoading.next(false);

                                    subscriber.next(task);
                                    subscriber.complete();
                                });

                            });
                } else {
                    this.workgroupmode = true;
                    this.taskService.getTask(params.uuid)
                        .subscribe(
                            (task: Task) => {
                                this.task = task;

                                combineLatest(this.getRequestArray(task)
                                ).subscribe(resultList => {
                                    this.workstepCount = resultList[0] as number;
                                    this.workplaces = resultList[1] as string[];
                                    this.worksteps = resultList[2] as WorkStep[];
                                    this.availableDepartments = resultList[3] as Department[];
                                    this.availableUsers = resultList[4] as User[];
                                    this.labels = resultList[5] as string[];
                                    this.children = resultList[6] as Task[];
                                    this.departmentConfigs = this.getDepartmentConfig(resultList[7] as Department[]);
                                    this.instructions = resultList[8] as Instruction[];
                                    this.handbooks = resultList[9] as Handbook[];
                                    this.noworkplace = (resultList[10] as ConfigList).noworkplace === 'true';
                                    this.showTime = resultList[11] as { time: string };

                                    this.errorWorksteps = resultList[12] as WorkStep[];

                                    if (!isNil(this.errorWorksteps.find((step: WorkStep) => +step.selectionorder === +task.step))) {
                                        this.worksteps = this.worksteps.concat(this.errorWorksteps);
                                    }

                                    this.notificationService.isLoading.next(false);

                                    subscriber.next(task);
                                    subscriber.complete();
                                });

                            });
                }
            });
        });
    }

    // parse status -> set empty to null
    public parseStatus = (status: string) => status === '' ? null : status;

    public compareObjects = (o1: WorkStep, o2: WorkStep): boolean => o1.name === o2.name && o1.label === o2.label;

    public stepIsCompleted(step) {
        return R.contains(step.selectionorder, this.alreadyDoneWorksteps);
    }

    ngOnDestroy(): void {
        this.app.onToggleMode('side');
        if (!isNil(this.task) && this.task.lockedByUser) {
            this.taskService.unlocktask(this.task.id).subscribe(value => console.log('Unlocked Task:', value));
        }
    }
    // get department configuration
    private getDepartmentConfig(configurations: Department[]): Department[] {
        return R.map(config => {
            config.name = this.getNamesForDepartmentIds(config);
            return config;
        }, configurations);
    }

    // get department names
    private getNamesForDepartmentIds(config: Department) {
        const filterDepartments = department => R.includes(department.id, config.departments);
        return R.join(', ', R.pluck('name', R.filter(filterDepartments, this.availableDepartments)));
    }

}
