import {Component, OnInit, VERSION} from '@angular/core';
import {environment} from 'src/environments/environment';
import {buildcode, version} from '../../../../package.json';
import browser from 'browser-detect';
import {BrowserDetectInfo} from 'browser-detect/dist/types/browser-detect.interface';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {

    private appVersion: string = version + ' ' + buildcode;
    private ngVersion: string = VERSION.full;
    private date: Date = new Date();
    private clientName: string = environment.name;
    private userAgent: string = navigator.userAgent;
    private lang: string = navigator.language;
    private browser: BrowserDetectInfo = browser();

    constructor() {

    }

    ngOnInit() {

    }
}
